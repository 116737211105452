import { ReactNode } from 'react'
import { Container } from 'react-bootstrap'
import { Outlet } from 'react-router'

import Topnav from '../../components/topnav/Topnav'
import Footer from '../aux/Footer'

export default function PrivateLayout({ children } : { children?: ReactNode }) {
  return (
    <>
      <Topnav />

      <Container
        className="d-flex flex-column flex-auto"
        style={{ 
          marginTop: '85px',
          minHeight: 'calc(100vh - 85px)'
        }}
      >
        { children ?? <Outlet /> }
      </Container>

      <Container>
        <Footer />
      </Container>
    </>
  )
}
