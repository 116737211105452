import { useState } from 'react'
import { Button, Card, Col, Modal, ProgressBar, Row } from 'react-bootstrap'
import { Download } from 'react-feather'
import { Link } from 'react-router-dom'

import { currencyFormat } from '../helpers'
import api from '../utils/api'

import LoadingButton from './LoadingButton'
import OfferingModalImages from './OfferingModalImages'

export interface OfferingResource {
  id: number,
  name: string,
  type: string,
  status: string,
  currency: string,
  amount: number,
  invested_pct: number,
  focus: string,
  area: number,
  expected_irr: number,
  location: string,
  description: string,
  meta: { [key: string]: any },
  images: Array<any>,
}

export default function Offering({ offering }: { offering: OfferingResource }) {
  const [loadingInfo, setLoadingInfo] = useState<boolean>(false)
  const [showImages, setShowImages] = useState<boolean>(false)
  const [showThesis, setShowThesis] = useState<boolean>(false)

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="d-flex align-items-center mb-4">
            { offering.name }

            { offering.meta.type === 'individual' && (
              <span className="badge fs-5 bg-info-soft ms-3">
                Individual property
              </span>
            ) }

            { offering.meta.type === 'portfolio' && (
              <span className="badge fs-5 bg-success-soft ms-3">
                Portfolio
              </span>
            ) }
          </h2>

          <Row>
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-column justify-content-between"
            >
              <img
                src={offering.images[0]?.preview ?? null}
                style={{
                  cursor: 'pointer',
                  maxWidth: '100%',
                  maxHeight: '200px'
                }}
                className="mx-auto mb-3 rounded"
                alt={offering.name}
                onClick={offering.images.length ? () => setShowImages(true) : undefined}
              />

              <p className="text-justify small">
                { offering.description }
              </p>

              <p className="text-center mb-0">
                <Button
                  variant="outline-info"
                  className="w-100"
                  onClick={() => setShowThesis(true)}
                >
                  Investment Thesis
                </Button>
              </p>
            </Col>

            <Col
              xs={12}
              lg={4}
              className="ps-5 pe-3"
            >
              <div className="my-2">
                <p className="small text-muted mb-2 pb-1">
                  Investment Overview
                </p>

                <div className="small d-flex flex-row justify-content-between mb-2 pb-1">
                  <b>Crop</b>

                  <span>{ offering.meta.crop }</span>
                </div>

                <div className="small d-flex flex-row justify-content-between mb-2 pb-1">
                  <b>Location</b>

                  <span>{ offering.location }</span>
                </div>

                <div className="small d-flex flex-row justify-content-between mb-2 pb-1">
                  <b>Area</b>

                  <span>{ offering.meta.area }</span>
                </div>

                <div className="small d-flex flex-row justify-content-between mb-2 pb-1">
                  <b>Project Operator</b>

                  <span>{ offering.meta.project_operator }</span>
                </div>

                <div className="small d-flex flex-row justify-content-between mb-2 pb-1">
                  <b>Expected Duration</b>

                  <span>{ offering.meta.expected_duration }</span>
                </div>

                <p className="small text-muted mt-4 mb-2 pb-1">
                  Sustainability
                </p>

                { offering.meta.sustainability.map((point: string) => (
                  <p className="small mb-2">
                    – { point }
                  </p>
                )) }
              </div>
            </Col>

            <Col
              xs={12}
              lg={4}
              className="d-flex flex-column justify-content-between ps-5"
            >
              <div className="mb-3">
                <p className="small text-muted mb-1">
                  Total Investment Offering
                </p>

                <p
                  className="text-info mb-0"
                  style={{ fontSize: '24px' }}
                >
                  { currencyFormat(offering.amount, offering.currency) }
                </p>
              </div>

              <div className="my-2">
                <p className="small text-muted mb-2 pb-1">
                  Key Stats
                </p>

                <div className="small d-flex flex-row justify-content-between mb-2 pb-1">
                  <b>Farmland</b>

                  <span>{ offering.meta.farmland }</span>
                </div>

                <div className="small d-flex flex-row justify-content-between mb-2 pb-1">
                  <b>Productive Area</b>

                  <span>{ offering.meta.productive_area }</span>
                </div>

                <div className="small d-flex flex-row justify-content-between mb-2 pb-1">
                  <b>Gross Cash Yield</b>

                  <span>{ offering.meta.yield }</span>
                </div>

                <div className="small d-flex flex-row justify-content-between mb-0">
                  <b>Net Anual IRR</b>

                  <span>{ offering.meta.irr }</span>
                </div>
              </div>

              <div className="mt-3 mb-3">
                <p className="small text-center mb-1">
                  <b>{offering.invested_pct}%</b> invested
                </p>

                <ProgressBar
                  now={offering.invested_pct}
                  variant="info"
                  className="progress-sm"
                />
              </div>

              <Row className="mt-3">
                <Col className="pe-2">
                  <LoadingButton
                    busy={loadingInfo}
                    variant="outline-info"
                    className="w-100"
                    onClick={() => {
                      setLoadingInfo(true)

                      api.get(`/offerings/${offering.id}/summary`)
                        .then(response => {
                          if (response !== undefined) {
                            window.location.href = response.data.data.url
                          }
                          setLoadingInfo(false)
                        })
                        .catch(() => {
                          setLoadingInfo(false)
                        })
                    }}
                  >
                    <Download
                      size="14px"
                      className="me-2"
                    />
                    
                    More info
                  </LoadingButton>
                </Col>

                { offering.status !== 'funded' && (
                  <Col className="ps-2">
                    { 
                      offering.status === 'future'
                        ? (
                          <Link
                            to={`/offerings/${offering.id}/register`}
                            className="btn btn-info w-100"
                          >
                            Register
                          </Link>
                        )
                        : (
                          <Link
                            to={`/offerings/${offering.id}/invest`}
                            className="btn btn-info w-100 d-flex flex-row justify-content-center align-items-center"
                          >
                            <span>Invest</span>
                          </Link>
                        )
                    }
                  </Col>
                ) }
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      { /* Investment thesis */ }

      <Modal
        show={showThesis}
        onHide={() => setShowThesis(false)}
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">
            { offering.name }: Investment Thesis
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Content pending</p>
        </Modal.Body>
      </Modal>

      { /* Offering images */ }

      {
        offering.images.length > 0 && (
          <OfferingModalImages
            show={showImages}
            onHide={() => setShowImages(false)}
            name={offering.name}
            images={offering.images}
          />
        )
      }
    </>
  )
}
