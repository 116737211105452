import { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { AlertTriangle } from 'react-feather'
import { useLocation } from 'react-router-dom'

import UserContact from '../components/account/UserContact'
import UserEmail from '../components/account/UserEmail'
import UserPassword from '../components/account/UserPassword'
import UserPersonal from '../components/account/UserPersonal'
import api from '../utils/api'

import Loading from './aux/Loading'

export default function AccountUser() {
  const location = useLocation()
  const [user, setUser] = useState<{[key: string]: string}|null>(null)

  useEffect(() => {
    const abortController = new AbortController()

    api.get('/user', { signal: abortController.signal })
      .then(response => {
        if (response !== undefined) {
          setUser(response.data.data)
        }
      })

    return () => abortController.abort()
  }, [])

  if (user === null) {
    return (
      <Loading />
    )
  }

  return (
    <>
      <h2 className="ms-2 mb-3">
        User information
      </h2>

      <p className="text-muted ms-2 mb-4">
        This information is related to the currently logged in user.
      </p>

      { location.state?.email === 'verify_expired' && (
        <Alert variant="danger">
          <AlertTriangle
            size="16px"
            className="me-2"
          /> 
          The email address verification link has expired. Please try again below.
        </Alert>
      ) }

      <UserPersonal user={user} />

      <UserContact user={user} />

      {/* <UserEmail user={user} /> */}

      <UserPassword />
    </>
  )
}
