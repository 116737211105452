import { useEffect, useState } from 'react'
import { Card, Col, ListGroup, Modal, Pagination, Row, Table } from 'react-bootstrap'
import { AlertTriangle, ArrowLeft, ArrowRight } from 'react-feather'
import { Link } from 'react-router-dom'

import { currencyFormat } from '../../helpers'
import api from '../../utils/api'
import Loading from '../../views/aux/Loading'

export default function TransactionsTable({ investorId }: { investorId: number|null}) {
  const [data, setData] = useState<{ [key: string]: any }[]|null>(null)
  const [page, setPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)

  useEffect(() => {
    if (investorId === null) {
      setData([])
      return
    }
    
    const abortController = new AbortController()

    setData(null)

    api.get(`/investors/${investorId}/transactions?page=${page}`, { signal: abortController.signal })
      .then(response => {
        if (response !== undefined) {
          setData(response.data.data)
          setLastPage(response.data.meta.last_page)
        }
      })

    return () => abortController.abort()
  }, [investorId, page])

  return (
    <>
      { data === null && (
        <Loading />
      ) }

      { (data !== null && data.length === 0) && (
        <div className="flex-auto d-flex flex-auto align-items-center">
          <div className="mx-auto text-muted">
            <p className="text-center">
              <AlertTriangle size="32px" />
            </p>

            <p className="text-center mb-2">
              <b>No transactions found.</b>
            </p>

            <p className="text-center mb-0">
              To start investing, <Link to="/offerings">have a look at our latest offerings</Link>.
            </p>
          </div>
        </div>
      ) }

      <Card className={(data !== null && data.length > 0) || lastPage > 1 ? '' : 'd-none'}>
        { (data !== null && data.length > 0) && (
          <ListGroup className="list-group-flush">
            { data.map((investment: { [key: string]: any }) => (
              <ListGroup.Item
                className="px-4"
                key={investment.id}
              >
                <Row className="align-items-center">
                  <Col
                    xs={12}
                    md={4}
                    className="overflow-ellipsis"
                  >
                    { investment.offering.name }
                  </Col>
                    
                  <Col
                    xs={12}
                    md={2}
                    className="text-center"
                  >
                    { currencyFormat(investment.amount, investment.currency) }
                  </Col>

                  <Col>
                    { investment.status === 'created' && (
                      <span className="badge bg-secondary-soft fs-5">Generating documents</span>
                    ) }

                    { investment.status === 'prepared' && (
                      <span className="badge bg-secondary-soft fs-5">Review pending</span>
                    ) }

                    { investment.status === 'reviewed' && (
                      <span className="badge bg-warning fs-5">Signature pending</span>
                    ) }

                    { investment.status === 'declined' && (
                      <span className="badge bg-danger-soft fs-5">Signature declined</span>
                    ) }

                    { investment.status === 'signed' && (
                      <span className="badge bg-info-soft fs-5">Payment pending</span>
                    ) }

                    { investment.status === 'paid' && (
                      <span className="badge bg-success-soft fs-5">Paid</span>
                    ) }
                  </Col>

                  <Col
                    xs={12}
                    md={2}
                    className="text-right"
                  >
                    { investment.updated_at }
                  </Col>

                  <Col
                    xs="auto"
                    className="text-right"
                  >
                    { (investment.status === 'created' || investment.status === 'prepared') && (
                      <Link
                        to={`/investments/${investment.id}/review`}
                        className="btn btn-primary btn-sm"
                      >
                          Review
                      </Link>
                    ) }

                    { (investment.status === 'reviewed') && (
                      <Link
                        to={`/investments/${investment.id}/sign`}
                        className="btn btn-primary btn-sm"
                      >
                          Sign
                      </Link>
                    ) }

                    { (investment.status === 'signed') && (
                      <Link
                        to={`/investments/${investment.id}/transfer`}
                        className="btn btn-primary btn-sm"
                      >
                          View
                      </Link>
                    ) }

                    { (investment.status === 'paid' || investment.status === 'declined') && (
                      <button
                        className="btn btn-primary btn-sm disabled"
                      >
                          View
                      </button>
                    ) }
                  </Col>
                </Row>
              </ListGroup.Item>
            )) }
          </ListGroup>
        ) }

        { lastPage > 1 && (
          <Card.Footer className={`d-flex justify-content-between ${data === null ? 'border-top-0' : ''}`}>
            <Pagination className="card-pagination pagination-tabs">
              <Pagination.Item
                className="ps-0 pe-4 border-end"
                disabled={page === 1}
                onClick={() => setPage(page-1)}
              >
                <ArrowLeft
                  size="1em"
                  className="me-1"
                /> Prev
              </Pagination.Item>
            </Pagination>

            <Pagination className="card-pagination pagination-tabs">
              {[...Array(lastPage)].map((option, index) => (
                <Pagination.Item
                  key={index}
                  active={index+1 === page}
                  onClick={() => setPage(index+1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
            </Pagination>

            <Pagination className="card-pagination pagination-tabs">
              <Pagination.Item
                className="ps-4 pe-0 border-start"
                disabled={page === lastPage}
                onClick={() => setPage(page+1)}
              >
                Next 
                <ArrowRight
                  size="1em"
                  className="ms-1"
                />
              </Pagination.Item>
            </Pagination>
          </Card.Footer>
        ) }
      </Card>
    </>
  )
}
