import { useEffect, useState } from 'react'
import { Alert, Card, Col, Row, Table } from 'react-bootstrap'
import { AlertTriangle } from 'react-feather'
import { Link } from 'react-router-dom'

import KycAlert from '../components/KycAlert'
import SelectInvestor from '../components/SelectInvestor'
import TransactionsFunds from '../components/account/TransactionsFunds'
import TransactionsTable from '../components/account/TransactionsTable'
import api from '../utils/api'
import { useAppSelector } from '../utils/hooks'
import { selectUser } from '../utils/store/auth'

import Loading from './aux/Loading'

export default function AccountTransactions() {
  const [investorId, setInvestorId] = useState<number|null>(null)

  return (
    <>
      <h2 className="ms-2 mb-3">
        Transactions
      </h2>

      <p className="text-muted ms-2 mb-4">
        This page shows your latest transactions in our investment offerings.
      </p>

      <SelectInvestor
        paragraphProps={{ className: 'ms-2 mb-4' }}
        onUpdate={(id: number) => setInvestorId(id)}
        whenNone={<KycAlert />}
      />

      {/* <TransactionsFunds investorId={investorId} /> */}

      <TransactionsTable investorId={investorId} />
    </>
  )
}
