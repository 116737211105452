import React, { HTMLProps, useEffect, useState } from 'react'

import { useAppSelector } from '../utils/hooks'
import { selectUser } from '../utils/store/auth'

export default function SelectInvestor ({ value, onUpdate, whenNone, whenOne, paragraphProps, selectProps }: { value?: number|null, onUpdate: Function, whenNone?: JSX.Element|null, whenOne?: JSX.Element|null, paragraphProps?: HTMLProps<HTMLParagraphElement>, selectProps?: HTMLProps<HTMLSelectElement>}) {
  const investors = useAppSelector(selectUser)?.investors ?? []
  const [investorId, setInvestorId] = useState<number|null>(value ?? (investors[0]?.id ?? null))

  useEffect(() => {
    onUpdate(investorId)
  }, [investorId, onUpdate])

  if (investors.length === 0) {
    return whenNone !== undefined
      ? whenNone
      : (
        <p {...paragraphProps}>
          Choose an investor:

          <input
            type="text"
            className="form-control d-inline ms-3"
            placeholder="No investor entities available"
            style={{ maxWidth: '300px' }}
            disabled
          />
        </p>
      )
  }

  if (investors.length === 1 && whenOne !== undefined) {
    return whenOne
  }

  return (
    <p {...paragraphProps}>
      Choose an investor:
           
      <select
        className="form-control form-select d-inline ms-3"
        onChange={e => setInvestorId(parseInt(e.target.value))}
        value={investorId ?? -1}
        {...selectProps}
        style={{ maxWidth: '300px' }}
      >
        { investors.map(investor => (
          <option
            key={investor.id}
            value={investor.id}
          >
            {investor.name}
          </option>
        )) }
      </select>
    </p>
  )
}
