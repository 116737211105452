import { Field } from 'formik'
import { Col, Row } from 'react-bootstrap'

import SelectCountry from '../SelectCountry'
import FloatingLabeledField from '../fields/FloatingLabeledField'
import FloatingLabeledFieldWrapper from '../fields/FloatingLabeledFieldWrapper'

import InvestorFieldsAddress from './InvestorFieldsAddress'
import InvestorFieldsBanking from './InvestorFieldsBanking'
import InvestorFieldsEmployment from './InvestorFieldsEmployment'

export default function InvestorFields({ type, values, errors }: { type: string, values: {[key: string]: string}, errors: {[key: string]: string} }) {
  return (
    <>
      {/* <InvestorFieldsAddress
        values={values}
        errors={errors}
      />

      <hr className="mt-3 mb-4" /> */}

      { type === 'natural' && (
        <>
          <InvestorFieldsEmployment
            values={values}
            errors={errors}
          />

          <hr className="mt-3 mb-4" />
        </>
      ) }

      <InvestorFieldsBanking
        values={values}
        errors={errors}
      />
    </>
  )
}
