import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAppSelector } from '../../utils/hooks'
import { selectUser } from '../../utils/store/auth'
import Loading from '../../views/aux/Loading'

export default function AuthComponent({ component }: { component: JSX.Element }) {
  const location = useLocation()
  const navigate = useNavigate()

  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  
  const user = useAppSelector(selectUser)

  const redirectToWelcome = user !== null
    && user.passed_welcome !== true
    && location.pathname !== '/welcome'

  useEffect(() => {
    // If status loaded and not authenticated, redirect to login
    if (!isLoading && !isAuthenticated) {
      (async (): Promise<void> => {
        await loginWithRedirect()
      })()
    }

    // If authenticated and must redirect to welcome, do it
    if (redirectToWelcome) {
      navigate('/welcome', { replace: true })
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, navigate, redirectToWelcome])

  return !isLoading && isAuthenticated && !redirectToWelcome
    ? component
    : <Loading />
}
