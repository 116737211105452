import { Col, Row } from 'react-bootstrap'

import FloatingLabeledField from '../fields/FloatingLabeledField'

export default function InvestorFieldsBanking({ values, errors }: { values: {[key: string]: string}, errors: {[key: string]: string} }) {
  return (
    <>
      <Row>
        <Col
          xs={12}
          md={8}
          className="pe-md-2"
        >
          <FloatingLabeledField
            name="iban"
            label="Bank account IBAN"
            errors={errors}
            divProps={{className: 'mb-3'}}
          />
        </Col>
        
        <Col
          xs={12}
          md={4}
          className="ps-md-2"
        >
          <FloatingLabeledField
            name="swift_bic"
            label="Bank account SWIFT / BIC"
            errors={errors}
            divProps={{className: 'mb-3'}}
          />
        </Col>
      </Row>

      <p className="text-muted small mb-0">
        This bank account will be used to pay out dividends from your investments. For anti money laundering purposes, it is important that the account holder is the entity from which you are investing. You may fill out this information in another moment.
      </p>
    </>
  )
}
