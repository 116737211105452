import { Field, FieldAttributes } from 'formik'
import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'

import api from '../utils/api'

export default function SelectCountry ({ eligible = false, ...props }: { eligible: boolean } & FieldAttributes<any>) {
  const [options, setOptions] = useState<{ [key: string]: string }[]|null>(null)

  useEffect(() => {
    const abortController = new AbortController()

    api.get(`/services/country/list${ eligible ? '?eligible=true' : '' }`, { signal: abortController.signal })
      .then(response => {
        if (response !== undefined) {
          setOptions(response.data.data)
        }
      })

    return () => abortController.abort()
  }, [])
  
  if (options === null) {
    return (
      <div className="form-control text-muted d-flex flex-row align-items-center justify-content-between">
        <span>
          Loading...
        </span>

        <Spinner 
          animation="border"
          size="sm"
          className="me-3"
        />
      </div>
    )
  }

  return (
    <Field
      as="select"
      {...props}
    >
      <option value=""></option>

      { options.map(option => (
        <option
          data-dial-code={option.dial_code}
          key={option.code}
          value={option.code}
        >
          { option.name }
        </option>
      )) }
    </Field>
  )
}
