import SumsubWebSdk from '@sumsub/websdk-react'
import axios from 'axios'
import { Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { AlertTriangle, ArrowLeft, CheckCircle, User } from 'react-feather'
import { Link, Navigate } from 'react-router-dom'

import LoadingButton from '../components/LoadingButton'
import SelectCountry from '../components/SelectCountry'
import InvestorFields from '../components/account/InvestorFields'
import VerifiedUserFields from '../components/account/VerifiedUserFields'
import FloatingLabeledField from '../components/fields/FloatingLabeledField'
import FloatingLabeledFieldWrapper from '../components/fields/FloatingLabeledFieldWrapper'
import { countryName, preventSubmitOnEnter } from '../helpers'
import api from '../utils/api'

import Loading from './aux/Loading'
import AuthLayout from './layouts/AuthLayout'

enum Status {
  CheckingIfExists,
  CompletingDetails,
  Sending,
  NotVerified,
  AlreadyInvestor
}

export default function AccountInvestorNewNatural() {
  const abortController = new AbortController()

  const [status, setStatus] = useState<Status>(Status.CheckingIfExists)
  const [user, setUser] = useState<{[key: string]: string}>({})
  const [errors, setErrors] = useState<{ [key: string]: string }>({})

  useEffect(() => {
    if (status === Status.CheckingIfExists) {
      api.get('/user', { signal: abortController.signal })
        .then(response => {
          if (response !== undefined) {
            if (response.data.data.is_investor === true) {
              setStatus(Status.AlreadyInvestor)
              return
            }

            if (response.data.data.kyc_status !== 'verified') {
              setStatus(Status.NotVerified)
              return
            }

            setUser(response.data.data)
            setStatus(Status.CompletingDetails)
          }
        })
    }

    return () => abortController.abort()
  }, [status])

  if (status === Status.AlreadyInvestor) {
    return (
      <AuthLayout
        headerRight={
          <Link
            to="/account/investor/new"
            className="btn btn-sm btn-outline-primary d-flex flex-row align-items-center"
          >
            <ArrowLeft
              size="12px"
              className="me-2"
            />

            Previous step
          </Link>
        }
      >
        <Col
          xs={12}
          md={10}
          lg={8}
          xl={6}
        >
          <h1 className="text-center mb-4">
            <CheckCircle size="36px" />
          </h1>

          <h2 className="text-center mb-4">
            Your investor account is ready
          </h2>

          <p className="text-center text-muted mb-5">
            Thanks for creating an account. You are now able to invest in our offerings.
          </p>

          <p className="text-center mb-0">
            <Link
              to="/offerings"
              className="btn btn-primary w-50"
            >
              Go to Offerings
            </Link>
          </p>
        </Col>
      </AuthLayout>
    )
  }

  if (status === Status.NotVerified) {
    return (
      <AuthLayout
        headerRight={
          <Link
            to="/account/investor/new"
            className="btn btn-sm btn-outline-primary d-flex flex-row align-items-center"
          >
            <ArrowLeft
              size="12px"
              className="me-2"
            />

            Previous step
          </Link>
        }
      >
        <Col
          xs={12}
          md={10}
          lg={8}
          xl={6}
        >
          <h1 className="text-center mb-4">
            <User size="36px" />
          </h1>

          <h2 className="text-center mb-4">
            Verify your identity
          </h2>

          <p className="text-center text-muted mb-5">
            Before investing, we need you to verify your identity and provide some documentation. Click the button below to get started.
          </p>

          <p className="text-center mb-0">
            <Link
              to="/account/user/verify?investor=natural"
              className="btn btn-primary w-50"
            >
              Start verifying
            </Link>
          </p>
        </Col>
      </AuthLayout>
    )
  }
  
  return (
    <AuthLayout
      headerRight={
        <Link
          to="/account/investor/new"
          className="btn btn-sm btn-outline-primary d-flex flex-row align-items-center"
        >
          <ArrowLeft
            size="12px"
            className="me-2"
          />

          Previous step
        </Link>
      }
    >
      <Col
        xs={12}
        sm={12}
        md={10}
        lg={9}
        xl={8}
      >
        <h1 className="text-center">
          Investing as a natural person
        </h1>

        <p className="text-center text-muted mb-5">
          Create an investor account for yourself in a few minutes.
        </p>

        { (status === Status.CheckingIfExists) && (
          <div className="py-5">
            <Loading />
          </div>
        ) }

        { (status === Status.CompletingDetails || status === Status.Sending) && (
          <>
            <Card>
              <Card.Body>
                <p>
                  You are about to create an investor account to invest on your own behalf. Before proceeding, please confirm that all the following details are correct.
                </p>

                <p className="text-muted">
                  If any of these details are incorrect, please go back to <Link to="/account/user">User information</Link>.
                </p>

                <hr className="mt-4 mb-3" />

                <VerifiedUserFields user={user} />

                <hr className="my-4" />

                <Formik
                  initialValues={{
                    address_line1: '',
                    address_line2: '',
                    address_city: '',
                    address_postal_code: '',
                    address_country: user.document_country || '',
                    employment_status: '',
                    profession: '',
                  }}
                  
                  onSubmit={(values) => {
                    setStatus(Status.Sending)

                    api.post('/investors/natural', values)
                      .then(response => {
                        if (response !== undefined) {
                          window.location.reload()
                        }
                      })
                      .catch(err => {
                        if (axios.isAxiosError(err)) {
                          if (err.response?.data?.errors !== undefined) {
                            setErrors(err.response.data.errors)
                          }
                        }
    
                        setStatus(Status.CompletingDetails)
                      })
                  }}
                >
                  {({ values }) => (
                    <Form onKeyDown={preventSubmitOnEnter}>
                      <InvestorFields
                        type="natural"
                        values={values}
                        errors={errors}
                      />
                      
                      <p className="text-center mt-4 mb-0">
                        <LoadingButton
                          type="submit"
                          busy={status === Status.Sending}
                          size="lg"
                          className="px-5 me-3"
                        >
                          Create account
                        </LoadingButton>
                      </p>
                    </Form>
                  ) }
                </Formik>
              </Card.Body>
            </Card>
          </>
        ) }
      </Col>
    </AuthLayout>
  )
}
