import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import { Formik, Form, Field } from 'formik'
import { ChangeEvent, useEffect, useState } from 'react'
import { Col, Row, Card, Modal } from 'react-bootstrap'
import { CheckCircle, Mail, X } from 'react-feather'
import { Link, Navigate } from 'react-router-dom'

import LoadingButton from '../components/LoadingButton'
import SelectCountry from '../components/SelectCountry'
import FloatingLabeledField from '../components/fields/FloatingLabeledField'
import FloatingLabeledFieldWrapper from '../components/fields/FloatingLabeledFieldWrapper'
import FloatingLabeledSelectField from '../components/fields/FloatingLabeledSelectField'
import api from '../utils/api'
import { useAppSelector } from '../utils/hooks'
import { selectUser } from '../utils/store/auth'

import Loading from './aux/Loading'
import AuthLayout from './layouts/AuthLayout'

interface FormValues {
  first_name: string;
  last_name: string;
  country: string;
  phone: string;
  investment_range: string;
  heard_from: string;
  terms_accepted: boolean;
}

enum Status {
  Ready,
  Sending,
  IneligibleByCountry,
  IneligibleByAmount,
  JoiningWaitlistByCountry,
  JoiningWaitlistByAmount,
  JoinedWaitlist
}

export default function Welcome() {
  const abortController = new AbortController()

  const user = useAppSelector(selectUser)
  const { logout } = useAuth0()

  const [status, setStatus] =  useState<Status>(Status.Ready)
  const [data, setData] = useState<{ [key: string]: any }|null>(null)
  const [errors, setErrors] = useState<{ [key: string]: any }>({})

  useEffect(() => {
    if (user?.passed_welcome !== true) {
      api.get('/user/welcome', { signal: abortController.signal })
        .then(response => {
          if (response !== undefined) {
            setData(response.data.data)
          }
        })
    }

    return () => abortController.abort()
  }, [])

  if (user?.passed_welcome === true) {
    return (
      <Navigate
        to="/offerings"
        state={{ welcome: true }}
        replace
      />
    )
  }

  if (data === null) {
    return (
      <Loading />
    )
  }
  
  
  return (
    <AuthLayout
      headerRight={
        <button
          onClick={() => logout({ returnTo: window.location.origin })}
          className="btn btn-sm btn-outline-primary d-flex flex-row align-items-center"
        > 
          Log out

          <X
            size="12px"
            className="ms-2"
          />
        </button>
      }
    >
      { data.email_verified === false && (
        <Col
          xs={12}
          md={10}
          lg={8}
          xl={6}
        >
          <h1 className="text-center mb-4">
            <Mail size="36px" />
          </h1>

          <h2 className="text-center mb-4">
            Verify your email address
          </h2>

          <p className="text-center text-muted">
            Thanks for signing up! We have sent a verification link to your email address. Please check your inbox and click on the link to complete your registration.
          </p>
        
          <p className="text-center text-muted mb-0">
            If the email does not arrive, please check your Spam folder or <Link to="/contact">contact us</Link>.
          </p>
        </Col>
      ) }

      { (data.joined_waitlist === true || status === Status.JoinedWaitlist) && (
        <Col
          xs={12}
          md={10}
          lg={8}
          xl={6}
        >
          <h1 className="text-center mb-4">
            <CheckCircle size="36px" />
          </h1>

          <h2 className="text-center mb-4">
            Thanks for joining our waitlist
          </h2>

          <p className="text-center text-muted mb-4">
            You have been successfully enrolled in our waitlist. We will inform you as soon as Bageera is available to investors of your profile.
          </p>
        
          <p className="text-center text-muted mb-0">
            <a
              href="https://www.bageera.co"
              className="btn btn-outline-primary px-5"
            > 
              Back to Home
            </a>
          </p>
        </Col>
      ) }

      { (data.joined_waitlist === false && data.email_verified === true && status !== Status.JoinedWaitlist) && (
        <Col
          xs={12}
          md={12}
          lg={9}
          xl={8}
          className="px-0 px-md-3"
        >
          <Card className="no-card-xs">
            <Card.Body className="p-4 pt-5 p-sm-5">
              <h1 className="mb-4 text-left">Almost ready!</h1>

              <p className="mb-4 text-muted">
                Thanks for creating an account at Bageera. Please fill out the following information and press the button <b>Get started</b>. You will be able to view our offerings immediately.
              </p>

              <Formik
                initialValues={{
                  first_name: data?.first_name || '',
                  last_name: data?.last_name || '',
                  country: data?.country || '',
                  phone: data?.phone || '',
                  investment_range: data?.investment_range || '',
                  heard_from: data?.heard_from || '',
                  terms_accepted: false,
                }}
                onSubmit={(values: FormValues) => {
                  setErrors({})

                  setStatus(Status.Sending)

                  api.post('/user/welcome', values, { signal: abortController.signal })
                    .then(response => {
                      if (response !== undefined) {
                        if (response.data?.error === 'ineligible_by_country') {
                          setStatus(Status.IneligibleByCountry)
                        } else if (response.data?.error === 'ineligible_by_amount') {
                          setStatus(Status.IneligibleByAmount)
                        } else {
                          window.location.reload()
                        }
                      }
                    })
                    .catch(err => {
                      if (axios.isAxiosError(err)) {
                        if (err.response?.data?.errors !== undefined) {
                          setErrors(err.response.data.errors)
                        }
                      }
      
                      setStatus(Status.Ready)
                    })
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <Row>
                      <Col
                        xs={12}
                        md={6}
                        className="pe-md-2"
                      >
                        <FloatingLabeledField
                          name="first_name"
                          label="First name"
                          errors={errors}
                          divProps={{className: 'mb-3'}}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={6}
                        className="ps-md-2"
                      >
                        <FloatingLabeledField
                          name="last_name"
                          label="Last name"
                          errors={errors}
                          divProps={{className: 'mb-3'}}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        xs={12}
                        md={6}
                        className="pe-md-2"
                      >
                        <FloatingLabeledFieldWrapper
                          name="country"
                          label="Country of residence"
                          errors={errors}
                          divProps={{className: 'mb-0'}}
                        >
                          <SelectCountry
                            className={
                              errors.country 
                                ? (values.country !== '' ? 'form-control is-invalid' : 'form-control is-invalid empty') 
                                : (values.country !== '' ? 'form-control' : 'form-control empty')
                            }
                            id="country"
                            name="country"
                            placeholder="Country of residence"
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                              const options = e.target.children

                              for (let i = 0; i < options.length; i++) {
                                const option = options[i]

                                if (option.getAttribute('value') === e.target.value) {
                                  if (values.phone.length <= 5) {
                                    setFieldValue('phone', option.getAttribute('data-dial-code') ?? '')
                                  }
                                }
                              }

                              setFieldValue('country', e.target.value)
                            }}
                          />
                        </FloatingLabeledFieldWrapper>
                      </Col>

                      <Col
                        xs={12}
                        md={6}
                        className="ps-md-2"
                      >
                        <FloatingLabeledField
                          name="phone"
                          label="Phone number (with prefix)"
                          errors={errors}
                          divProps={{className: 'mb-0'}}
                        />
                      </Col>
                    </Row>

                    <hr className="mb-4" />

                    <Row className="mb-2">
                      <Col
                        xs={12}
                        md={6}
                        className="pe-md-2"
                      >
                        <FloatingLabeledFieldWrapper
                          name="investment_range"
                          label="Estimated initial investment amount"
                          errors={errors}
                        >
                          <Field
                            as="select"
                            className={
                              errors.investment_range 
                                ? (values.investment_range !== '' ? 'form-control is-invalid' : 'form-control is-invalid empty') 
                                : (values.investment_range !== '' ? 'form-control' : 'form-control empty')
                            }
                            id="investment_range"
                            name="investment_range"
                            placeholder="Estimated initial investment amount"
                          >
                            <option value=""></option>

                            <option value="0-25,000">less than €25,000</option>

                            <option value="25,000-100,000">between €25,000 and €100,000</option>

                            <option value="100,000-250,000">between €100,000 and €250,000</option>

                            <option value="250,000+">more than €250,000</option>
                          </Field>
                        </FloatingLabeledFieldWrapper>
                      </Col>

                      <Col
                        xs={12}
                        md={6}
                        className="ps-md-2"
                      >
                        <FloatingLabeledSelectField
                          name="heard_from"
                          label="How did you hear about us?"
                          errors={errors}
                          values={values}
                        >
                          <option value=""></option>

                          <option value="Podcast">Podcast</option>

                          <option value="Facebook">Facebook</option>

                          <option value="Instagram">Instagram</option>

                          <option value="LinkedIn">LinkedIn</option>

                          <option value="Referred by an investor">Referred by an investor</option>

                          <option value="Twitter">Twitter</option>

                          <option value="Email Newsletter">Email Newsletter</option>

                          <option value="YouTube">YouTube</option>

                          <option value="Quora">Quora</option>

                          <option value="Direct Mail">Direct Mail</option>

                          <option value="Television">Television</option>

                          <option value="SiriusXM">SiriusXM</option>

                          <option value="Pandora">Pandora</option>

                          <option value="AltoIRA">AltoIRA</option>

                          <option value="TikTok">TikTok</option>

                          <option value="TuneIn">TuneIn</option>

                          <option value="Other">Other</option>
                        </FloatingLabeledSelectField>
                      </Col>
                    </Row>

                    <div className="text-muted mb-3">
                      <div className="form-check form-switch">
                        <Field
                          className="form-check-input"
                          type="checkbox"
                          id="terms_accepted"
                          name="terms_accepted"
                        />

                        <label
                          title=""
                          htmlFor="terms_accepted"
                          className="form-check-label"
                        >
                          &nbsp;I agree to Bageera's <a href="/">Terms of Use</a> and

                          {' '}<a href="/">Privacy Policy</a>.
                        </label>
                      </div>
                    </div>

                    <p className="mt-1 mb-0">
                      <LoadingButton
                        busy={status === Status.Sending}
                        type="submit"
                        size="lg"
                        className="mt-2 w-100"
                        disabled={values.terms_accepted === false || status === Status.Sending}
                      >
                        Get started
                      </LoadingButton>
                    </p>

                    <Modal
                      show={status === Status.IneligibleByCountry || status === Status.IneligibleByAmount || status === Status.JoiningWaitlistByCountry || status === Status.JoiningWaitlistByAmount}
                      onHide={() => setStatus(Status.Ready)}
                      centered
                    >
                      <Modal.Body className="text-center">
                        <p className="mb-4">
                          <img
                            src="/images/sign.png"
                            srcSet="/images/sign@2x.png 2x, /images/sign@3x.png 3x"
                            alt="Bageera"
                            style={{height: '50px'}}
                          />
                        </p>
                        
                        <h2 className="mb-4">
                          Join the waitlist
                        </h2>

                        { (status === Status.IneligibleByAmount || status === Status.JoiningWaitlistByAmount) && (
                          <>
                            <p className="px-3">
                              Unfortunately, Bageera is currently available only to investors who plan to invest more than €50,000. We are working to extend our services to other investor profiles with lower investment amounts in the near future.
                            </p>

                            <p className="mb-4 px-3">
                              Please click the button below to join our waitlist, so we can contact you as soon as when we can accept investors with your profile:
                            </p>
                          </>
                        ) }
                        
                        { (status === Status.IneligibleByCountry || status === Status.JoiningWaitlistByCountry) && (
                          <>
                            <p className="px-3">
                              Unfortunately, Bageera is currently available only to investors from the European Union. We are working to extend our services to other countries.
                            </p>

                            <p className="mb-4 px-3">
                              Please click the button below to join our waitlist, so we can contact you as soon as when we launch our platform in your country:
                            </p>
                          </>
                        ) }

                        <p className="mb-2">
                          <LoadingButton
                            busy={status === Status.JoiningWaitlistByCountry || status === Status.JoiningWaitlistByAmount}
                            size="lg"
                            className="w-50 mb-0"
                            onClick={() => {
                              setStatus(status === Status.IneligibleByAmount ? Status.JoiningWaitlistByAmount : Status.JoiningWaitlistByCountry)

                              api.post('/user/waitlist', { signal: abortController.signal })
                                .then(response => {
                                  if (response !== undefined) {
                                    setStatus(Status.JoinedWaitlist)
                                  }
                                })
                                .catch(() => {
                                  setStatus(status === Status.JoiningWaitlistByAmount ? Status.IneligibleByAmount : Status.IneligibleByCountry)
                                })
                            }}
                          >
                            Join the waitlist
                          </LoadingButton>
                        </p>
                      </Modal.Body>
                    </Modal>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      ) }
    </AuthLayout>
  )
}
