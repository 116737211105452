import { FieldProps } from 'formik'
import { HTMLProps } from 'react'
import { FloatingLabel, Form as ReactForm } from 'react-bootstrap'

interface FormGroupFieldProps {
  name: string,
  type?: string,
  label: string,
  errors?: {[key: string]: any},
  divProps?: HTMLProps<HTMLDivElement>,
  fieldProps?: FieldProps,
  showErrors?: boolean,
  children?: JSX.Element
}

export default function FloatingLabeledFieldWrapper({name, type, label, errors, divProps, fieldProps, children}: FormGroupFieldProps) {
  return (
    <div
      className="form-group"
      {...divProps}
    >
      <FloatingLabel
        controlId={name}
        label={label}
        className={`text-muted mb-2 ${(errors?.[name] ? 'is-invalid' : '')}`}
      >
        {children}

        {errors?.[name] && (
          <ReactForm.Control.Feedback type="invalid">
            {errors[name]}
          </ReactForm.Control.Feedback>
        )}
      </FloatingLabel>
    </div>
  )
}
