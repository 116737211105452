import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import api from '../utils/api'

import Loading from './aux/Loading'
import AuthLayout from './layouts/AuthLayout'

export default function InvestmentSign() {
  const { id } = useParams()

  useEffect(() => {
    const abortController = new AbortController()

    api.get(`/investments/${id}/sign`, { signal: abortController.signal })
      .then(response => {
        if (response !== undefined) {
          window.location.href = response.data.data.url
        }
      })
    
    return () => abortController.abort()
  }, [])

  return (
    <AuthLayout>
      <Loading />

      <h2 className="text-center mt-5">We are preparing your documents</h2>

      <p className="text-center">This will take less than a minute.</p>
    </AuthLayout>
  )
}
