import { Card } from 'react-bootstrap'
import { AlertTriangle } from 'react-feather'
import { Link } from 'react-router-dom'

export default function KycAlert() {
  return (
    <>
      <Card>
        <Card.Body className="d-block d-md-flex flex-row align-items-center p-3">
          <AlertTriangle
            size="20px"
            className="ms-1 me-3"
          />

          <span className="pe-3">
            Before investing, we will need you to verify your identity and provide us some last details.
          </span>

          <Link
            to="/account/investor/new"
            className="d-block d-md-inline mt-3 mt-md-0 flex-shrink-0 btn btn-sm btn-outline-primary"
          >
            Complete now &raquo;
          </Link>
        </Card.Body>
      </Card>
    </>
  )
}
