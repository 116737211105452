import { Field } from 'formik'
import { Col, Row } from 'react-bootstrap'

import FloatingLabeledFieldWrapper from '../fields/FloatingLabeledFieldWrapper'

export default function InvestorFieldsEmployment({ values, errors }: { values: {[key: string]: string}, errors: {[key: string]: string} }) {
  return (
    <>
      <Row>
        <Col
          xs={12}
          md={['employed', 'selfemployed'].includes(values.employment_status) ? 6 : 12}
          className={['employed', 'selfemployed'].includes(values.employment_status) ? 'pe-md-2' : ''}
        >
          <FloatingLabeledFieldWrapper
            name="employment_status"
            label="Employment status"
            errors={errors}
            divProps={{className: 'mb-3'}}
          >
            <Field
              as="select"
              className={
                errors.employment_status 
                  ? (values.employment_status !== '' ? 'form-control is-invalid' : 'form-control is-invalid empty') 
                  : (values.employment_status !== '' ? 'form-control' : 'form-control empty')
              }
              id="employment_status"
              name="employment_status"
              placeholder="Employment status"
            >
              <option value=""></option>

              <option value="employed">Employed</option>

              <option value="selfemployed">Self-employed</option>

              <option value="retired">Retired</option>

              <option value="student">Student</option>
            </Field>
          </FloatingLabeledFieldWrapper>
        </Col>

        <Col
          xs={12}
          md={6}
          className={!['employed', 'selfemployed'].includes(values.employment_status) ? 'd-none' : 'ps-md-2'}
        >
          <FloatingLabeledFieldWrapper
            name="profession"
            label="Profession"
            errors={errors}
            divProps={{className: 'mb-3'}}
          >
            <Field
              as="select"
              className={
                errors.profession 
                  ? (values.profession !== '' ? 'form-control is-invalid' : 'form-control is-invalid empty') 
                  : (values.profession !== '' ? 'form-control' : 'form-control empty')
              }
              id="profession"
              name="profession"
              placeholder="Profession"
            >
              <option value=""></option>

              { [1, 2, 3, 4, 5, 6, 7, 8, 9].map(idx => (
                <option
                  value={`Profession ${idx}`}
                  key={idx}
                >
                  Profession {idx}
                </option>
              )) }
            </Field>
          </FloatingLabeledFieldWrapper>
        </Col>
      </Row>
    </>
  )
}
