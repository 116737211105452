import SumsubWebSdk from '@sumsub/websdk-react'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { CheckCircle, X } from 'react-feather'
import { Link, useSearchParams } from 'react-router-dom'

import LoadingButton from '../components/LoadingButton'
import SelectCountry from '../components/SelectCountry'
import FloatingLabeledFieldWrapper from '../components/fields/FloatingLabeledFieldWrapper'
import api from '../utils/api'

import Loading from './aux/Loading'
import AuthLayout from './layouts/AuthLayout'

enum Status {
  CheckingIfVerified,
  ReadingIntro,
  LoadingSumsubToken,
  Verifying,
  LoadingDetails,
  AlreadyVerified
}

export default function AccountUserVerify() {
  const abortController = new AbortController()

  const [searchParams] = useSearchParams()

  const investorType = searchParams.get('investor')
  const backPath = ['natural', 'legal'].includes(investorType ?? '')
    ? `/account/investor/new/${investorType}`
    : '/account/user'

  const [status, setStatus] = useState<Status>(Status.CheckingIfVerified)
  const [accessToken, setAccessToken] = useState<string>('')

  useEffect(() => {
    if (status === Status.CheckingIfVerified) {
      api.get('/user', { signal: abortController.signal })
        .then(response => {
          if (response !== undefined) {
            if (response.data.data.kyc_status !== 'verified') {
              setStatus(Status.ReadingIntro)
            } else {
              setStatus(Status.AlreadyVerified)
            }
          }
        })
    }

    if (status === Status.LoadingSumsubToken) {
      api.get('/services/sumsub/token', { signal: abortController.signal })
        .then(response => {
          if (response !== undefined) {
            setAccessToken(response.data.data.token)
            setStatus(Status.Verifying)
          }
        })
    }

    if (status === Status.LoadingDetails) {
      api.post('/user/verify', { signal: abortController.signal })
        .then(response => {
          if (response !== undefined) {
            setStatus(Status.AlreadyVerified)
          }
        })
    }

    return () => abortController.abort()
  }, [status])

  if (status === Status.AlreadyVerified) {
    return (
      <AuthLayout>
        <Col
          xs={12}
          md={10}
          lg={8}
          xl={6}
        >
          <h1 className="text-center mb-4">
            <CheckCircle size="36px" />
          </h1>

          <h2 className="text-center mb-4">
            Thanks for verifying your identity
          </h2>

          <p className="text-center text-muted mb-5">
            You have successfully verified your identity. 
            
            { investorType === 'natural' && ' You can now continue setting up an investment account for yourself.' }

            { investorType === 'legal' && ' You can now continue setting up an investment account for your legal entity.' }

            { investorType === null && ' You are now ready to create an investment account for either yourself or a legal entity.' }
          </p>

          <p className="text-center mb-0">
            { investorType === null && (
              <>
                <Link
                  to={backPath}
                  className="btn btn-outline-primary px-4 me-3"
                >
                  Back to User information
                </Link>
                
                <Link
                  to='/account/investor/new'
                  className="btn btn-primary px-4"
                >
                  Create an investor account
                </Link>
              </>
            ) }

            { investorType !== null && (
              <Link
                to={backPath}
                className="btn btn-primary w-50"
              >
                { investorType === 'natural' && 'Invest as a natural person' }

                { investorType === 'legal' && 'Invest as a legal entity' }
              </Link>
            ) }
          </p>
        </Col>
      </AuthLayout>
    )
  }
  
  return (
    <AuthLayout
      headerRight={
        <Link
          to={backPath}
          className="btn btn-sm btn-outline-primary d-flex flex-row align-items-center"
        >
          <X
            size="12px"
            className="me-2"
          />

          Cancel verification
        </Link>
      }
    >
      <Col
        xs={12}
        sm={12}
        md={10}
        lg={9}
        xl={8}
      >
        <h1 className="text-center">
          Verify your identity
        </h1>

        <p className="text-center text-muted mb-5">
          Our identification process will get you ready to invest in a few minutes.
        </p>

        { (status === Status.CheckingIfVerified || status === Status.LoadingDetails) && (
          <div className="py-5">
            <Loading />
          </div>
        ) }

        { (status === Status.ReadingIntro || status === Status.LoadingSumsubToken) && (
          <Card>
            <Card.Body>
              <p>
                Before investing, either on your own or as a legal representative of an entity, we are required to verify your identity and documentation. This information will be collected by our partner Sumsub, encrypted and securely transmitted to our servers.
              </p>

              <p>
                Please make sure you have the following ready:
              </p>

              <ul>
                <li>A national ID card, a passport or a residence permit.</li>

                <li>Access to a camera connected to this computer, or access to a mobile phone with frontal camera (in that case, you will be provided a link).</li>
              </ul>

              <p>
                All data will be processed according to maximum compliance standards and RGPD.
              </p>

              <p className="text-center mt-4 mb-0">
                <LoadingButton
                  busy={status === Status.LoadingSumsubToken}
                  className="btn btn-primary px-5"
                  onClick={() => setStatus(Status.LoadingSumsubToken)}
                >
                  Start identification process
                </LoadingButton>
              </p>
            </Card.Body>
          </Card>
        ) }

        { status === Status.Verifying && (
          <SumsubWebSdk
            accessToken={accessToken}
            expirationHandler={async () => {
              const response = await api.get('/services/sumsub/token', { signal: abortController.signal })

              if (response !== undefined) {
                return Promise.resolve(response.data.data.token)
              } else {
                return Promise.reject()
              }
            }}
            config={{ lang: 'en' }}
            onMessage={
              (type, payload: {[key: string]: any}) => {
                if (type === 'idCheck.applicantStatus' && payload.reviewResult?.reviewAnswer === 'GREEN') {
                  setStatus(Status.LoadingDetails)
                }
              }
            }
          />
        ) }
      </Col>
    </AuthLayout>
  )
}
