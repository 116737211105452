import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { Alert, Card, Modal } from 'react-bootstrap'
import { AlertTriangle, CheckCircle } from 'react-feather'
import { Link, useLocation } from 'react-router-dom'

import KycAlert from '../components/KycAlert'
import Offering, { OfferingResource } from '../components/Offering'
import api from '../utils/api'
import { useAppSelector } from '../utils/hooks'
import { selectUser } from '../utils/store/auth'

import Loading from './aux/Loading'

export default function Offerings() {
  const location = useLocation()

  const investors = useAppSelector(selectUser)?.investors ?? []

  const [offerings, setOfferings] = useState<{ current: OfferingResource[], future: OfferingResource[], pending: { id: number, name: string }[] }|null>(null)
  const [showWelcome, setShowWelcome] = useState<boolean>(location.state?.welcome === true)
  
  useEffect(() => {
    const abortController = new AbortController()

    api.get('/offerings', { signal: abortController.signal })
      .then(response => {
        if (response !== undefined) {
          setOfferings(response.data.data)
        }
      })

    return () => abortController.abort()
  }, [])

  if (offerings === null) {
    return <Loading />
  }

  return (
    <>
      <motion.div exit={{ opacity: 0 }}>
        <h1 className="mt-5 mb-5">
          Current Offerings
        </h1>

        { offerings.pending.map(offering => (
          <Card key={offering.id}>
            <Card.Body className="d-block d-md-flex flex-row align-items-center p-3">
              <AlertTriangle
                size="16px"
                className="ms-2 me-3"
              /> 

              <span className="pe-3">
                Your investment in {offering.name} is still pending.
              </span>
              
              <Link
                to={`/offerings/${offering.id}/invest`}
                className="d-block d-md-inline mt-3 mt-md-0 flex-shrink-0 btn btn-sm btn-outline-primary"
              >
                Complete now &raquo;
              </Link>
            </Card.Body>
          </Card>
        )) }

        { location.state?.investmentCancelled && (
          <Alert variant="success">
            <CheckCircle
              size="16px"
              className="me-3"
            /> 

            Your investment in { location.state?.investmentCancelled.offeringName } has been successfully cancelled.
          </Alert>
        ) }

        { investors.length === 0 && <KycAlert /> }

        { offerings.current.map(offering => (
          <Offering
            offering={offering}
            key={offering.id}
          />
        )) }

        { offerings.future.length > 0 && (
          <h1 className="mt-5 mb-5">
            Future Offerings
          </h1>
        ) }

        { offerings.future.map(offering => (
          <Offering
            offering={offering}
            key={offering.id}
          />
        )) }

        <Modal
          show={showWelcome}
          centered
          backdrop="static"
        >
          <Modal.Body className="text-center">
            <p className="mb-4">
              <img
                src="/images/sign.png"
                srcSet="/images/sign@2x.png 2x, /images/sign@3x.png 3x"
                alt="Bageera"
                style={{height: '50px'}}
              />
            </p>
            
            <h2 className="mb-4">
              Welcome to Bageera
            </h2>

            <p className="mb-4 px-5">
              Thanks for signing up to Bageera! You are now able to see our current and future offerings. You will need to verify your identity and provide us some last details before investing.
            </p>

            <p className="mb-0">
              <button
                className="btn btn-lg btn-primary w-50"
                onClick={() => setShowWelcome(false)}
              >
                Continue
              </button>
            </p>
          </Modal.Body>
        </Modal>
      </motion.div>
    </>
  )
}
