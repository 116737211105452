import axios, { AxiosError, AxiosResponse } from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000, // Request timeout
  withCredentials: true,
})

export default api

function containsFiles(object: { [key: string|number|symbol]: any }): boolean {
  return Object.keys(object)
    .some(key => object[key] !== null 
      && typeof object[key] === 'object'
      && (object[key] instanceof File || containsFiles(object[key])))
}

function objectToFormData(object: { [key: string|number]: any }, formData: FormData, prefix: string = ''): FormData {
  for (const key in object) {
    const value = object[key]
    const newKey = prefix ? `${prefix}[${key}]` : key

    if (Array.isArray(value)) {
      value.forEach(elem => {
        formData.append(`${newKey}[]`, elem)
      })
    } else if (value !== null && typeof value === 'object' && !(value instanceof File)) {
      objectToFormData(formData, value, newKey)
    } else if (value !== null) {
      formData.append(newKey, value)
    }
  }

  return formData
}

export function setupAxiosInterceptors({ onForbidden, onNotFound, onUnauthorized }: { onForbidden: Function, onNotFound: Function, onUnauthorized: Function }) {
  api.interceptors.request.use(
    config => {
      if (config.data !== undefined && containsFiles(config.data)) {
        const data = objectToFormData(config.data, new FormData())

        return {
          ...config,
          data: data,
          headers: {
            ...config.headers,
            'Content-Type': 'multipart/form-data'
          }
        }
      }

      return config
    },
    (error: AxiosError) => {
      // Do something with request error
      Promise.reject(error)
    },
  )

  api.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      if (error instanceof axios.Cancel) {
        return Promise.resolve()
      }
  
      if (error.response?.status === 401) {
        onUnauthorized()
        return Promise.resolve()
      }

      if (error.response?.status === 403) {
        onForbidden()
        return Promise.resolve()
      }

      if (error.response?.status === 404) {
        onNotFound()
        return Promise.resolve()
      }
  
      return Promise.reject(error)
    },
  )
}
