import { useEffect, useState } from 'react'
import { Card, Col, Dropdown, Row } from 'react-bootstrap'
import { Doughnut } from 'react-chartjs-2'
import { AlertTriangle, MoreVertical } from 'react-feather'
import { Link } from 'react-router-dom'

import KycAlert from '../components/KycAlert'
import SelectInvestor from '../components/SelectInvestor'
import { Chart, ChartLegend } from '../components/vendor'
import { countryName, currencyFormat } from '../helpers'
import api from '../utils/api'

import Loading from './aux/Loading'

export interface PortfolioOverviewResource {
  investments: {
    id: number,
    offering_id: number,
    offering_name: string,
    offering_location: string,
    offering_country: string,
    offering_image: string,
    currency: string,
    amount: number,
    created_at: string,
  }[],

  total_value: number,
  balance: number,

  countries: {
    [key: string]: number
  }
}

export default function Portfolio() {
  const [investorId, setInvestorId] = useState<number|null>(null)
  const [data, setData] = useState<PortfolioOverviewResource|null>(null)
  
  useEffect(() => {
    setData(null)

    if (investorId === null) {
      return
    }

    const abortController = new AbortController()

    api.get(`/investors/${investorId}/portfolio`, { signal: abortController.signal })
      .then(response => {
        if (response !== undefined) {
          setData(response.data.data)
        }
      })

    return () => abortController.abort()
  }, [investorId])

  const heading = (
    <>
      <h1 className="mt-5 mb-5">
        My portfolio
      </h1>

      <SelectInvestor
        value={investorId}
        paragraphProps={{ className: 'mb-4' }}
        onUpdate={(id: number) => setInvestorId(id)}
        whenNone={<KycAlert />}
        whenOne={null}
      />
    </>
  )

  if (investorId !== null && data === null) {
    return (
      <>
        {heading}

        <Loading />
      </>
    )
  }

  const countriesCount = Object.keys(data?.countries ?? []).length

  const doughnutData = { 
    labels: Object.keys(data?.countries ?? []).map(country => countryName(country)), 
    datasets: [{ 
      data: Object.values(data?.countries ?? []),
      backgroundColor: [...Array(countriesCount)]
        .map((value, index) => `rgba(1, 106, 246, ${ Math.pow(0.5, index) })`)
    }] 
  }

  return (
    <div className="flex-auto">
      {heading}

      <Row>
        <Col
          xs={12}
          md={8}
        >
          {
            (data?.investments ?? []).length === 0 && (
              <div 
                className="d-flex flex-row align-items-center"
                style={{
                  height: '250px',
                }}
              >
                <div className="mx-auto text-muted">
                  <p className="text-center">
                    <AlertTriangle size="32px" />
                  </p>

                  <p className="text-center mb-2">
                    <b>No investments in your portfolio.</b>
                  </p>

                  <p className="text-center mb-0">
                    To start investing, <Link to="/offerings">have a look at our latest offerings</Link>.
                  </p>
                </div>
              </div>
            )
          }

          { 
            (data?.investments ?? []).map(investment => (
              <Card key={investment.id}>
                <Card.Body>
                  <Row className="align-items-center">
                    <Col xs="auto">
                      <img
                        src={investment.offering_image}
                        className="rounded"
                        alt={investment.offering_name}
                        style={{
                          width: '120px',
                          height: '90px',
                          objectFit: 'cover',
                        }}
                      />
                    </Col>

                    <Col className="ms-n2">
                      <h4 className="mb-1 name">
                        { investment.offering_name }
                      </h4>

                      <Card.Text className="small text-muted mb-2">
                        Location: { investment.offering_location } ({ countryName(investment.offering_country) })
                      </Card.Text>

                      <p className="small mb-0">
                        Invested <b>{ currencyFormat(investment.amount, investment.currency) }</b> on <b>{ investment.created_at }</b>
                      </p>
                    </Col>

                    <Col xs="auto">
                      <Dropdown align="end">
                        <Dropdown.Toggle
                          as="span"
                          className="dropdown-ellipses"
                          role="button"
                        >
                          <MoreVertical size="17px" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            href='#'
                          >
                            Download Investment Thesis
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))
          }
        </Col>

        <Col
          xs={12}
          md={4}
        >
          <Card>
            <Card.Header>
              <h4 className="card-header-title">
                Total Portfolio Value
              </h4>
            </Card.Header>

            <Card.Body className="pb-3">
              <p className="h1 text-info mb-3">
                { currencyFormat(data?.total_value ?? 0, 'EUR') }
              </p>

              { ((data?.balance ?? 0) !== 0) && (
                <p className="text-muted">
                  + <b>{ currencyFormat(data?.balance ?? 0) }</b> available to invest
                </p>
              ) }
            </Card.Body>
          </Card>

          <Card>
            <Card.Header>
              <h4 className="card-header-title">
                Portfolio By Country
              </h4>
            </Card.Header>

            <Card.Body>
              {
                (data?.investments ?? []).length === 0 
                  ? (
                    <div 
                      className="d-flex flex-row align-items-center"
                      style={{
                        height: '250px',
                      }}
                    >
                      <div className="mx-auto text-muted">
                        <p className="text-center">
                          <AlertTriangle size="32px" />
                        </p>

                        <p className="text-center mb-0">
                          No investments
                        </p>
                      </div>
                    </div>
                  ) 
                  : (
                    <>
                      <Chart layout="appended">
                        <Doughnut
                          data={doughnutData}
                          options={{
                            plugins: {
                              tooltip: {
                                callbacks: {
                                  afterLabel: function () {
                                    return '%'
                                  },
                                },
                              },
                            },
                          }}
                        />
                      </Chart>

                      <ChartLegend
                        data={doughnutData}
                      />
                    </>
                  )
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
