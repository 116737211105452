import { Col, Row } from 'react-bootstrap'

export default function Footer() {
  return (
    <footer className="pt-5 pt-md-6 pb-3">
      <Row>
        <Col
          xs={12}
          md={4}
          lg={3}
          className="mb-3 mb-sm-0"
        >
          <a
            href="https://www.bageera.co"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="mb-4"
              src="/images/logo.png"
              srcSet="/images/logo@2x.png 2x, /images/logo@3x.png 3x"
              alt="Bageera"
              style={{ height: '36px' }}
            />
          </a>

          <h4>Address:</h4>

          <p className="mb-4">
            WeWork Aviation House<br/>

            125 Kingsway<br/>

            London WC2B 6NH
          </p>

          <h4>Contact:</h4>

          <p className="mb-4">
            <a
              href="mailto:hello@bageera.co"
              className="text-primary"
            >
              hello@bageera.co
            </a>
          </p>

          <p className="mb-5">
            <a
              href="https://www.linkedin.com/company/bageerainvest/"
              target="_blank"
              rel="noreferrer"
              className="d-flex flex-row align-items-center"
            >
              <img
                src="/images/linkedin.png"
                srcSet="/images/linkedin@2x.png 2x, /images/linkedin@3x.png 3x"
                alt="LinkedIn page"
                className="me-3"
              />

              Follow us on LinkedIn
            </a>
          </p>
        </Col>

        <Col
          xs={0}
          md={0}
          lg={3}
        >

        </Col>

        <Col
          xs={12}
          sm={6}
          md={6}
          lg={3}
          className="mb-4 mb-sm-0"
        >
          <h2>Company</h2>

          <a
            href="https://www.bageera.co/#why-agriculture"
            className="d-block text-primary mb-3"
          >
            Why Agriculture?
          </a>

          <a
            href="https://www.bageera.co/#how-it-works"
            target="_blank"
            className="d-block text-primary mb-3"
            rel="noreferrer"
          >
            How It Works
          </a>

          <a
            href="https://www.bageera.co/about"
            target="_blank"
            className="d-block text-primary mb-3"
            rel="noreferrer"
          >
            About Us
          </a>

          <a
            href="https://www.bageera.co/about#careers"
            target="_blank"
            className="d-block text-primary mb-3"
            rel="noreferrer"
          >
            Careers
          </a>
        </Col>

        <Col
          xs={12}
          sm={6}
          lg={3}
        >
          <h2>Legal</h2>

          <a
            href="https://www.bageera.co/privacy"
            target="_blank"
            className="d-block text-primary mb-3"
            rel="noreferrer"
          >
            Privacy Policy
          </a>

          <a
            href="https://www.bageera.co/terms"
            target="_blank"
            className="d-block text-primary mb-3"
            rel="noreferrer"
          >
            Terms &amp; Conditions
          </a>

          <a
            href="https://www.bageera.co/cookies"
            target="_blank"
            className="d-block text-primary mb-3"
            rel="noreferrer"
          >
            Cookies Policy
          </a>
        </Col>
      </Row>

      <hr className="mt-4 mb-4" />

      <Row className="align-items-center mb-4">
        <Col className="text-gray-600 mb-4 mb-md-0">
          &copy; 2022 Bageera Ltd. All rights reserved.
        </Col>

        <Col
          xs={12}
          md="auto"
          className="d-flex align-items-center justify-content-end"
        >
          <a href="https://www.greentech.earth/">
            <img
              src="/images/greentech.png"
              srcSet="/images/greentech@2x.png 2x, /images/greentech@3x.png 3x"
              alt="Green Tech Alliance"
              className="me-4"
            />
          </a>

          <a href="https://www.decadeonrestoration.org/">
            <img
              src="/images/undecade.png"
              srcSet="/images/undecade@2x.png 2x, /images/undecade@3x.png 3x"
              alt="United Nations Decade on Ecosystem Restoration"
            />
          </a>
        </Col>
      </Row>
    </footer>
  )
}
