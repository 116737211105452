import { Col, Row } from 'react-bootstrap'

import { countryName } from '../../helpers'

export default function VerifiedUserFields({ user }: { user: {[key: string]: string} }) {
  return (
    <Row>
      <Col
        xs={12}
        md={4}
        className="mt-3 mb-2"
      >
        <p className="small text-muted mb-1">First name</p>

        <p className="mb-0">
          { user.first_name }
        </p>
      </Col>

      <Col
        xs={12}
        md={4}
        className="mt-3 mb-2"
      >
        <p className="small text-muted mb-1">Last name</p>

        <p className="mb-0">
          { user.last_name }
        </p>
      </Col>
          
      <Col
        xs={12}
        md={4}
        className="mt-3 mb-2"
      >
        <p className="small text-muted mb-1">Birth date</p>

        <p className="mb-0">
          { user.birth_date }
        </p>
      </Col>
          
      <Col
        xs={12}
        md={4}
        className="mt-3 mb-2"
      >
        <p className="small text-muted mb-1">Place of birth</p>

        <p className="mb-0">
          { user.birth_place }
        </p>
      </Col>
          
      <Col
        xs={12}
        md={4}
        className="mt-3 mb-2"
      >
        <p className="small text-muted mb-1">Country of birth</p>

        <p className="mb-0">
          { countryName(user.birth_country) }
        </p>
      </Col>

      <Col
        xs={12}
        md={4}
        className="mt-3 mb-2"
      >
        <p className="small text-muted mb-1">Nationality</p>

        <p className="mb-0">
          { countryName(user.nationality) }
        </p>
      </Col>
          
      <Col
        xs={12}
        md={4}
        className="mt-3 mb-2"
      >
        <p className="small text-muted mb-1">Document type</p>

        <p className="mb-0">
          { user.document_type && { 'id_card': 'ID card', 'passport': 'Passport', 'residence_permit': 'Residence permit', 'drivers': 'Driver\'s license' }[user.document_type] }
        </p>
      </Col>

      <Col
        xs={12}
        md={4}
        className="mt-3 mb-2"
      >
        <p className="small text-muted mb-1">Document number</p>

        <p className="mb-0">
          { user.document_number }
        </p>
      </Col>

      <Col
        xs={12}
        md={4}
        className="mt-3 mb-2"
      >
        <p className="small text-muted mb-1">Document expiry date</p>

        <p className="mb-0">
          { user.document_expiry }
        </p>
      </Col>

      <Col
        xs={12}
        md={4}
        className="mt-3 mb-2"
      >
        <p className="small text-muted mb-1">Country of document</p>

        <p className="mb-0">
          { countryName(user.document_country) }
        </p>
      </Col>

      <Col
        xs={12}
        md={8}
        className="mt-3 mb-2"
      >
        <p className="small text-muted mb-1">Legal address</p>

        <p className="mb-0">
          { user.address_line1 }
          
          { user.address_line2 && ` – ${user.address_line2}` }
          
          { `, ${user.address_postal_code} ${user.address_city} (${countryName(user.country)})` }
        </p>
      </Col>
    </Row>
  )
}
