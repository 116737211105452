import currencySymbol from './currencySymbol'

export default function currencyFormat(amount: number, currency = 'EUR', forceDecimals = false) {
  const amountString = forceDecimals
    ? amount.toFixed(2)
    : amount.toString()

  const number = amountString.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  const symbol = currencySymbol(currency)

  return `${symbol}${number}`
}
