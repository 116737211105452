import axios from 'axios'
import { Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Card, Col, InputGroup, Row, Form as ReactForm, Modal, } from 'react-bootstrap'
import { AlertTriangle, ArrowLeft, X } from 'react-feather'
import { Link, useNavigate, useParams } from 'react-router-dom'

import LoadingButton from '../components/LoadingButton'
import { countryName, currencyFormat } from '../helpers'
import api from '../utils/api'

import Loading from './aux/Loading'
import AuthLayout from './layouts/AuthLayout'

enum CancelModal {
  Hidden,
  Visible,
  Loading
}

export default function InvestmentReview() {
  const { id } = useParams()

  const abortController = new AbortController()

  const navigate = useNavigate()

  const [investment, setInvestment] = useState<{ [key: string]: any }|null>(null)
  const [cancelModal, setCancelModal] = useState<CancelModal>(CancelModal.Hidden)
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)

  const load = async () => {
    const response = await api.get(`/investments/${id}/review`, { signal: abortController.signal })

    if (response !== undefined) {
      if (response.data.data.agreement_url !== null) {
        setInvestment(response.data.data)
      } else {
        setTimeout(load, 1500, abortController)
      }
    }
  }

  useEffect(() => {
    load()

    return () => abortController.abort()
  }, [])

  if (investment === null) {
    return (
      <AuthLayout>
        <Loading />

        <h2 className="text-center mt-5">We are preparing your documents</h2>

        <p className="text-center">This will take less than a minute.</p>
      </AuthLayout>
    )
  }

  return (
    <AuthLayout
      headerRight={
        <div className="d-flex flex-row">
          <Link
            to="/offerings"
            className="btn btn-sm btn-outline-primary d-flex flex-row align-items-center me-3"
          >
            <ArrowLeft
              size="12px"
              className="me-2"
            />

            Continue later
          </Link>

          <button
            className="btn btn-sm btn-outline-danger d-flex flex-row align-items-center"
            onClick={() => setCancelModal(CancelModal.Visible)}
          >
            <X
              size="12px"
              className="me-2"
            />

            Cancel investment
          </button>
        </div>
      }
    >
      <Col xs={12}>
        <h1 className="mt-4 ms-2 mb-4">
          Invest in: &nbsp;{ investment.offering.name }
        </h1>

        <ol className="breadcrumb ms-2 mb-4">
          <li className="breadcrumb-item text-muted">1. Investment details</li>

          <li className="breadcrumb-item text-primary">2. Review and sign agreement</li>

          <li className="breadcrumb-item text-muted">3. Transfer funds</li>
        </ol>
      
        <Card>
          <Card.Header>
            <h4 className="card-header-title">
              Review agreement
            </h4>
          </Card.Header>

          <Card.Body className="">
            <p className="mb-4">
              This is a preview of the share sale agreement you are about to sign. If you agree with the terms and conditions of this agreement and have verified that all information is correct, press <b>Proceed to signature</b> to sign this document electronically via DocuSign.
            </p>

            <button 
              className="btn btn-primary px-5 mb-4 d-block ms-auto"
              onClick={() => setShowConfirmModal(true)}
            >
              Proceed to signature
            </button>

            <hr className="my-4" />
                
            <iframe
              src={`${investment.agreement_url}#toolbar=0`}
              width="100%"
              height="600px"
              title="Offering agreement"
            />
          </Card.Body>
        </Card>

        <Modal
          show={cancelModal !== CancelModal.Hidden}
          onHide={() => setCancelModal(CancelModal.Hidden)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="mb-0">
              Confirm cancellation
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p className="mb-4">
              Are you sure you want to cancel this investment? If you want to invest in the future, you will have to fill the investment details again.
            </p>

            <p className="text-right mb-0">
              <button
                className="btn btn-outline-secondary me-3 px-4"
                onClick={() => setCancelModal(CancelModal.Hidden)}
              >
                Close
              </button>

              <LoadingButton
                busy={cancelModal === CancelModal.Loading}
                onClick={() => {
                  setCancelModal(CancelModal.Loading)

                  api.delete(`/investments/${id}`, { signal: abortController.signal })
                    .then(response => {
                      if (response !== undefined) {
                        navigate('/offerings', {
                          state: {
                            investmentCancelled: {
                              offeringName: investment.offering.name
                            }
                          }
                        })
                      }
                    })
                    .catch(() => {
                      setCancelModal(CancelModal.Visible)
                    })
                }}
                className="px-4"
                variant="danger"
              >
                Cancel investment
              </LoadingButton>
            </p>
          </Modal.Body>
        </Modal>

        <Modal
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="mb-0">
              Confirm review
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p className="mb-3">
              Are you sure you want to proceed to sign the agreement? Please make sure all the information is correct and you agree with the terms and conditions. 
            </p>

            <p className="mb-4">
              You will not be able to modify or cancel your investment after you confirm.
            </p>

            <p className="text-right mb-0">
              <button
                className="btn btn-outline-secondary me-3 px-4"
                onClick={() => setShowConfirmModal(false)}
              >
                Close
              </button>

              <Link
                to={`/investments/${id}/sign`}
                className="btn btn-primary px-4"
              >
                Proceed to signature
              </Link>
            </p>
          </Modal.Body>
        </Modal>
      </Col>
    </AuthLayout>
  )
}
