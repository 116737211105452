import axios from 'axios'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { CheckCircle } from 'react-feather'

import api from '../../utils/api'
import LoadingButton from '../LoadingButton'
import FloatingLabeledField from '../fields/FloatingLabeledField'
import FloatingLabeledFieldWrapper from '../fields/FloatingLabeledFieldWrapper'

enum Status {
  Loaded,
  Sending,
  Updated
}

export default function UserContact({ user }: { user: {[key: string]: string} }) {
  const abortController = new AbortController()

  const [errors, setErrors] = useState<{[key: string]: string}>({})
  const [status, setStatus] = useState<Status>(Status.Loaded)

  useEffect(() => {
    return () => abortController.abort()
  }, [])

  return (
    <Card>
      <Card.Header>
        <h4 className="card-header-title">
          Contact details
        </h4>
      </Card.Header>

      <Card.Body>
        <Formik
          initialValues={{
            phone: user.phone,
          }}
          onSubmit={(values, formikBag) => {
            setStatus(Status.Sending)

            api.put('/user/contact', values, { signal: abortController.signal })
              .then(response => {
                if (response !== undefined) {
                  formikBag.resetForm({ values: values })
                  setStatus(Status.Updated)
                }
              })
              .catch(err => {
                if (axios.isAxiosError(err)) {
                  if (err.response?.data?.errors !== undefined) {
                    setErrors(err.response.data.errors)
                  }
                }
  
                setStatus(Status.Loaded)
              })
          }}
        >
          {({ values, dirty }) => (
            <Form>
              <Row>
                <Col
                  xs={12}
                  md={6}
                  className="pe-md-2"
                >
                  <FloatingLabeledFieldWrapper
                    name='email'
                    label='Email address'
                    divProps={{className: 'mb-3', style: {opacity: 0.8}}}
                  >
                    <input
                      type="text"
                      className="form-control"
                      value={user.email}
                      readOnly
                      style={{backgroundColor: 'rgba(0, 0, 0, 0.02)', color: 'rgba(0, 0, 0, 0.5)'}}
                    />
                  </FloatingLabeledFieldWrapper>
                </Col>

                <Col
                  xs={12}
                  md={6}
                  className="ps-md-2"
                >
                  <FloatingLabeledField
                    name="phone"
                    label="Phone number (with prefix)"
                    errors={errors}
                    divProps={{className: 'mb-3'}}
                  />
                </Col>
              </Row>

              <p className="mt-3 mb-0">
                <LoadingButton
                  busy={status === Status.Sending}
                  type="submit"
                  size="lg"
                  className="px-5 me-3"
                  disabled={status === Status.Sending || (status === Status.Updated && !dirty)}
                >
                  Update details
                </LoadingButton>

                { status === Status.Updated && !dirty && (
                  <span className="text-success">
                    <CheckCircle
                      size="12pt"
                      className="me-2"
                    />
                    Updated successfully
                  </span> 
                ) }
              </p>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  )
}
