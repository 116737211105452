import { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { AlertTriangle, CheckCircle, X } from 'react-feather'
import { Link, Navigate, useParams } from 'react-router-dom'

import api from '../utils/api'

import Loading from './aux/Loading'
import AuthLayout from './layouts/AuthLayout'

enum Status {
  Loading,
  Signed,
  Declined,
  Pending
}

export default function InvestmentSigned() {
  const { id } = useParams()
  const [status, setStatus] = useState<Status>(Status.Loading)

  useEffect(() => {
    const abortController = new AbortController()

    api.get(`/investments/${id}/signed`, { signal: abortController.signal })
      .then(response => {
        if (response !== undefined) {
          if (response.data.data.status === 'signed') {
            setStatus(Status.Signed)
          } else if (response.data.data.status === 'declined') {
            setStatus(Status.Declined)
          } else if (response.data.data.status === 'pending') {
            setStatus(Status.Pending)
          }
        }
      })

    return () => abortController.abort()
  }, [])

  if (status === Status.Loading) {
    return (
      <AuthLayout>
        <Loading />

        <h2 className="text-center mt-5">Receiving your signature</h2>

        <p className="text-center">This will take less than a minute.</p>
      </AuthLayout>
    )
  }

  if (status === Status.Declined) {
    return (
      <AuthLayout>
        <Col
          xs={12}
          md={10}
          lg={8}
          xl={6}
        >
          <h1 className="text-center mb-4">
            <X size="36px" />
          </h1>
  
          <h2 className="text-center mb-4">
            Signature declined
          </h2>
  
          <p className="text-center text-muted mb-5">
            You have declined to sign the agreement. Your investment has been rejected.
          </p>

          <p className="text-center mb-0">
            <Link
              to="/offerings"
              className="btn btn-primary w-50"
            >
              Go to Offerings
            </Link>
          </p>
        </Col>
      </AuthLayout>
    )
  }

  if (status === Status.Pending) {
    return (
      <AuthLayout>
        <Col
          xs={12}
          md={10}
          lg={8}
          xl={6}
        >
          <h1 className="text-center mb-4">
            <AlertTriangle size="36px" />
          </h1>
  
          <h2 className="text-center mb-4">
            Signature not completed
          </h2>
  
          <p className="text-center text-muted mb-5">
            We have not received your signature. Please try again.
          </p>

          <p className="text-center mb-0">
            <Link
              to={`/investments/${id}/sign`}
              className="btn btn-primary w-50"
            >
              Proceed to signature
            </Link>
          </p>
        </Col>
      </AuthLayout>
    )
  }

  return (
    <Navigate
      to={`/investments/${id}/transfer`}
    />
  )
}
