import axios from 'axios'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { countryName } from '../../helpers'
import api from '../../utils/api'
import LoadingButton from '../LoadingButton'
import SelectCountry from '../SelectCountry'
import FloatingLabeledField from '../fields/FloatingLabeledField'
import FloatingLabeledFieldWrapper from '../fields/FloatingLabeledFieldWrapper'

import VerifiedUserFields from './VerifiedUserFields'

interface FormValues {
  first_name: string|null;
  last_name: string|null;
  phone: string|null;
  country: string|null;
}

export default function UserPersonal({ user }: { user: {[key: string]: string} }) {
  const abortController = new AbortController()

  const [buttonBusy, setButtonBusy] = useState<boolean>(false)

  const [errors, setErrors] = useState<FormValues>({
    first_name: null,
    last_name: null,
    phone: null,
    country: null,
  })

  useEffect(() => {
    return () => abortController.abort()
  }, [])

  if (user.kyc_status === 'verified') {
    return (
      <Card>
        <Card.Header>
          <h4 className="card-header-title">
            Personal information
          </h4>
        </Card.Header>

        <Card.Body>
          <p className="text-muted mb-3">
            This information was obtained when you verified your identity. If you want to edit it, please <Link to="/contact">contact us</Link>.
          </p>

          <VerifiedUserFields user={user} />
        </Card.Body>
      </Card>
    )
  }

  return (
    <Card>
      <Card.Header>
        <h4 className="card-header-title">
          Personal information
        </h4>
      </Card.Header>

      <Card.Body>
        <p className="text-muted mb-4">
          This is the information you provided about yourself when you signed up. Once you <Link to="/account/user/verify">verify your identity</Link>, this information will be updated with the details from your proof of identity.
        </p>

        <Formik
          initialValues={{
            first_name: user.first_name,
            last_name: user.last_name,
            phone: user.phone,
            country: user.country,
          }}
          onSubmit={(values: FormValues) => {
            setButtonBusy(true)

            api.put('/user/personal', values, { signal: abortController.signal })
              .then(response => {
                if (response !== undefined) {
                  window.location.reload()
                }
              })
              .catch(err => {
                if (axios.isAxiosError(err)) {
                  if (err.response?.data?.errors !== undefined) {
                    setErrors(err.response.data.errors)
                  }
                }
  
                setButtonBusy(false)
              })
          }}
        >
          {({ values }) => (
            <Form>
              <Row>
                <Col
                  xs={12}
                  md={6}
                  className="pe-md-2"
                >
                  <FloatingLabeledField
                    name="first_name"
                    label="First name"
                    errors={errors}
                    divProps={{className: 'mb-3'}}
                  />
                </Col>

                <Col
                  xs={12}
                  md={6}
                  className="ps-md-2"
                >
                  <FloatingLabeledField
                    name="last_name"
                    label="Last name"
                    errors={errors}
                    divProps={{className: 'mb-3'}}
                  />
                </Col>
              </Row>

              <Row>
                <Col
                  xs={12}
                  md={6}
                  className="pe-md-2"
                >
                  <FloatingLabeledFieldWrapper
                    name="country"
                    label="Country of residence"
                    errors={errors}
                    divProps={{className: 'mb-0'}}
                  >
                    <SelectCountry
                      className={
                        errors.country 
                          ? (values.country !== '' ? 'form-control is-invalid' : 'form-control is-invalid empty') 
                          : (values.country !== '' ? 'form-control' : 'form-control empty')
                      }
                      eligible={true}
                      id="country"
                      name="country"
                      placeholder="Country of residence"
                    />
                  </FloatingLabeledFieldWrapper>
                </Col>
              </Row>

              <p className="mt-2 mb-0">
                <LoadingButton
                  busy={buttonBusy}
                  type="submit"
                  size="lg"
                  className="mt-2 px-5 ms-auto"
                  disabled={buttonBusy}
                >
                  Update details
                </LoadingButton>
              </p>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  )
}
