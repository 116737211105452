import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function UserPassword() {
  return (
    <Card>
      <Card.Header>
        <h4 className="card-header-title">
          Password
        </h4>
      </Card.Header>

      <Card.Body>
        {
          true
            ? (
              <>
                <p className="text-muted">
                  In order to change your password, log out from your account and then go to the Login form. Click on the <b>Forgot password?</b> link to receive an email message that will allow you to reset your password.
                </p>

                <p className="text-muted mb-0">
                  If you do not have access to your email address anymore, please <Link to="/contact">contact us</Link>.
                </p>
              </>
            )
            : (
              <p className="text-muted mb-0">
                Your account is related to an external entity provider, such as Google or Microsoft. If you want to change the password with which you log in into Bageera, please refer to that provider.
              </p>
            )
        }
      </Card.Body>
    </Card>
  )
}
