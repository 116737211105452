import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { Container, Dropdown, Nav, Navbar } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'

import { useAppSelector } from '../../utils/hooks'
import { selectUser } from '../../utils/store/auth'

export default function Topnav() {
  const location = useLocation()
  const user = useAppSelector(selectUser)

  const { logout } = useAuth0()

  const [scroll, setScroll] = useState<boolean>(false)

  useEffect(() => {
    const onScroll = () => {
      const scrollCheck = window.scrollY > 25
      
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck)
      }
    }

    document.addEventListener('scroll', onScroll)

    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [scroll])

  return (
    <>
      <Navbar
        expand="lg"
        className={scroll ? 'scroll-down' : ''}
        collapseOnSelect={true}
        fixed="top"
      >
        <Container>
          <Navbar.Toggle />

          <Link
            to="/"
            className="order-lg-first ms-2 ps-2 me-auto"
          >
            <img
              src="/images/logo.png"
              srcSet="/images/logo@2x.png 2x, /images/logo@3x.png 3x"
              alt="Bageera"
              style={{ height: '36px' }}
            />
          </Link>

          { user !== null && (
            <Dropdown
              align="end"
              className="order-lg-last"
            >
              <Dropdown.Toggle
                as="a"
                size="sm"
                role="button"
                className="d-flex flex-row align-items-center nav-link pe-0"
              >
                { user.name }
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Link to="/account">
                  <Dropdown.Item as="span">My account</Dropdown.Item>
                </Link>

                <Dropdown.Divider />

                <Dropdown.Item onClick={() => logout({ returnTo: window.location.origin })}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) }

          <Navbar.Collapse>
            <Nav>
              <Nav.Item>
                <Link to="/offerings">
                  <Nav.Link
                    as="span"
                    active={location.pathname === '/offerings'}
                  >
                    Offerings
                  </Nav.Link>
                </Link>
              </Nav.Item>

              <Nav.Item>
                <Link to="/portfolio">
                  <Nav.Link
                    as="span"
                    active={location.pathname === '/portfolio'}
                  >
                    My portfolio
                  </Nav.Link>
                </Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}
