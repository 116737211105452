import axios from 'axios'
import { Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { CheckCircle, Plus } from 'react-feather'
import { Link } from 'react-router-dom'

import LoadingButton from '../components/LoadingButton'
import SelectCountry from '../components/SelectCountry'
import SelectInvestor from '../components/SelectInvestor'
import InvestorFields from '../components/account/InvestorFields'
import VerifiedUserFields from '../components/account/VerifiedUserFields'
import FloatingLabeledField from '../components/fields/FloatingLabeledField'
import FloatingLabeledFieldWrapper from '../components/fields/FloatingLabeledFieldWrapper'
import { countryName } from '../helpers'
import api from '../utils/api'

import Loading from './aux/Loading'

export default function AccountInvestor() {
  const abortController = new AbortController()

  const [investorId, setInvestorId] = useState<number|null>(null)
  const [data, setData] = useState<({ [key: string]: string } & { legal_representatives: { [key: string]: string }[] })|null>(null)

  const [status, setStatus] = useState<string>('ready')

  const [errors, setErrors] = useState<{ [key: string]: any }>({})

  useEffect(() => {
    setData(null)

    if (investorId === null) {
      return
    }

    setStatus('ready')

    api.get(`/investors/${investorId}`, { signal: abortController.signal })
      .then(response => {
        if (response !== undefined) {
          setData(response.data.data)
        }
      })
    
    return () => abortController.abort()
  }, [investorId])

  return (
    <>
      <h2 className="ms-2 mb-3">
        Investor information
      </h2>

      <p className="text-muted ms-2 mb-4">
        This is information is related to the entities which you can invest on behalf of.
      </p>

      <Row>
        <Col>
          <SelectInvestor
            paragraphProps={{ className: 'ms-2 mb-4' }}
            onUpdate={(id: number) => setInvestorId(id)}
            whenNone={
              <Card>
                <Card.Body>
                  <p className="mb-4">
                  You have not created an investor entity yet. In order to invest, you have to verify your identity and provide us some last details about the entity on behalf of which you are going to invest, either yourself as a natural personal or a legal entity for which you are the legal representative.
                  </p>

                  <p className="text-center mb-0">
                    <Link
                      to="/account/investor/new"
                      className="btn btn-primary"
                    >
                      Create a new investor entity
                    </Link>
                  </p>
                </Card.Body>
              </Card>
            }
          />
        </Col>

        { investorId !== null && (
          <Col xs="auto">
            <Link
              to="/account/investor/new"
              state={{ referer: '/account/investor' }}
              className="btn btn-success"
            >
              <Plus
                size="16px"
                className="me-2"
              />
              New investor
            </Link>
          </Col>
        ) }
      </Row>

      { investorId !== null && data === null && (
        <Loading />
      ) }

      { investorId !== null && data !== null && (
        <Formik
          initialValues={data}
          
          onSubmit={(values, formikBag) => {
            setStatus('loading')

            setErrors({})

            api.put(`/investors/${investorId}`, values, { signal: abortController.signal })
              .then(response => {
                if (response !== undefined) {
                  formikBag.resetForm({ values: values })
                  setStatus('updated')
                }
              })
              .catch(err => {
                if (axios.isAxiosError(err)) {
                  if (err.response?.data?.errors !== undefined) {
                    setErrors(err.response.data.errors)
                  }
                }
  
                setStatus('ready')
              })
          }}
        >
          {({ values, dirty }) => {
            const naturalInfo = (user: { [key: string]: string }) => (
              <VerifiedUserFields user={user} />
            )

            const staticCards = data.type === 'natural'
              ? (
                <Card>
                  <Card.Header>
                    <h4 className="card-header-title">
                      Identification details
                    </h4>
                  </Card.Header>

                  <Card.Body>
                    <p className="text-muted mb-3">
                      If you want to edit any of these details, please <Link to="/contact">contact us</Link>.
                    </p>

                    { naturalInfo(data) }
                  </Card.Body>
                </Card>
              )
              : (
                <>
                  <Card>
                    <Card.Header>
                      <h4 className="card-header-title">
                        Identification details
                      </h4>
                    </Card.Header>

                    <Card.Body>
                      <p className="text-muted mb-3">
                        If you want to edit any of these details, please <Link to="/contact">contact us</Link>.
                      </p>

                      <Row>
                        <Col
                          xs={12}
                          md={6}
                          className="mt-3 mb-2"
                        >
                          <p className="small text-muted mb-1">Entity name</p>

                          <p className="mb-0">
                            { data.entity_name }
                          </p>
                        </Col>

                        <Col
                          xs={12}
                          md={6}
                          className="mt-3 mb-2"
                        >
                          <p className="small text-muted mb-1">Company number</p>

                          <p className="mb-0">
                            { data.entity_number }
                          </p>
                        </Col>

                        <Col
                          xs={12}
                          md={12}
                          className="mt-3 mb-2"
                        >
                          <p className="small text-muted mb-1">Legal address</p>

                          <p className="mb-0">
                            { data.entity_address } ({ countryName(data.entity_country) })
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>

                  <Card>
                    <Card.Header>
                      <h4 className="card-header-title">
                        Legal representative details
                      </h4>
                    </Card.Header>

                    <Card.Body>
                      <p className="text-muted mb-3">
                        If you want to edit any of these details, please <Link to="/contact">contact us</Link>.
                      </p>

                      { data.legal_representatives.map((user, index) => (
                        <div key={user.id}>
                          { data.legal_representatives.length > 1 && (
                            <p className="small font-weight-bold mt-4 mb-1">
                              Legal representative {index+1}: { `${user.first_name} ${user.last_name}` }
                            </p>
                          ) }

                          { naturalInfo(user) }
                        </div>
                      )) }
                    </Card.Body>
                  </Card>
                </>
              )

            return (
              <Form>
                { staticCards }

                <Card>
                  <Card.Header>
                    <h4 className="card-header-title">
                      Other details
                    </h4>
                  </Card.Header>

                  <Card.Body>
                    <InvestorFields
                      type={data.type}
                      values={values}
                      errors={errors}
                    />

                    <p className="mt-4 mb-0">
                      <LoadingButton
                        busy={status === 'loading'}
                        type="submit"
                        size="lg"
                        className="px-5 me-3"
                        disabled={status === 'loading' || (status === 'updated' && !dirty)}
                      >
                        Update details
                      </LoadingButton>

                      { status === 'updated' && !dirty && (
                        <span className="text-success">
                          <CheckCircle
                            size="12pt"
                            className="me-2"
                          />
                          Updated successfully
                        </span> 
                      ) }
                    </p>
                  </Card.Body>
                </Card>
              </Form>
            )
          }}
        </Formik>
      ) }
    </>
  )
}
