import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import './styles/theme.scss'

import { BrowserRouter } from 'react-router-dom'

import App from './App'
import Auth0ProviderWithHistory from './components/auth/Auth0ProviderWithHistory'
import { setupAxiosInterceptors } from './utils/api'
import store from './utils/store'
import { setStatusCode } from './utils/store/status'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <App />
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

const { dispatch } = store

setupAxiosInterceptors({
  onForbidden: () => {
    dispatch(setStatusCode(403))
  },

  onNotFound: () => {
    dispatch(setStatusCode(404))
  },

  onUnauthorized: () => {
    dispatch(setStatusCode(401))
  }
})

