import classNames from 'classnames'
import { HTMLProps, useEffect } from 'react'

import ChartDefaults from './ChartDefaults'

//
// Chart
//

function Chart({ layout, size, className, ...props }: { layout: string, size?: string, className?: string } & HTMLProps<HTMLDivElement>) {
  const classes = classNames('chart', layout && `chart-${layout}`, size && `chart-${size}`, className)

  useEffect(function () {
    ChartDefaults()
    
    return function () {
      const tooltip = document.getElementById('chart-tooltip')

      if (tooltip?.parentNode) {
        tooltip.parentNode.removeChild(tooltip)
      }
    }
  }, [])

  return (
    <div
      className={classes}
      {...props}
    />
  )
}

//
// Chart legend
//

function ChartLegend({ className, data, ...props }: { className?: string, data: { labels: string[], datasets: { data: number[], backgroundColor: string[] }[] } }) {
  const classes = classNames('chart-legend', className)

  const items = data.labels.map(function (label: string, index: number) {
    const color = data.datasets[0].backgroundColor[index]

    return (
      <ChartLegendItem
        color={color}
        label={label}
        key={index}
      />
    )
  })

  return (
    <div
      className={classes}
      {...props}
    >
      {items}
    </div>
  )
}

//
// Chart legend item
//

function ChartLegendItem({ color, label }: { color: string, label: string }) {
  return (
    <div className="chart-legend-item">
      <ChartLegendIndicator color={color} />
      
      {label}
    </div>
  )
}

//
// Chart legend indicator
//

function ChartLegendIndicator({ color }: { color: string }) {
  return (
    <i
      className="chart-legend-indicator"
      style={{ backgroundColor: color }}
    />
  )
}

export { Chart, ChartLegend }
