import axios from 'axios'
import { Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Card, Col, InputGroup, Row, Form as ReactForm, ListGroup, } from 'react-bootstrap'
import { AlertTriangle, X } from 'react-feather'
import NumberFormat from 'react-number-format'
import { Link, Navigate, useParams } from 'react-router-dom'

import LoadingButton from '../components/LoadingButton'
import { countryName, currencyFormat, currencySymbol } from '../helpers'
import api from '../utils/api'

import Loading from './aux/Loading'
import AuthLayout from './layouts/AuthLayout'

enum Status {
  Loading,
  ReadingIntro,
  HasExistingInvestments,
  ChoosingDetails,
  SendingDetails,
  RedirectToReview,
}

export default function OfferingInvest() {
  const abortController = new AbortController()

  const { id } = useParams()
  const [investors, setInvestors] = useState<{ [key: string]: any }[]|null>(null)
  const [offering, setOffering] = useState<{ [key: string]: any }|null>(null)

  const [status, setStatus] = useState<Status>(Status.Loading)
  const [errors, setErrors] = useState<{ [key: string]: any }>({})

  const [investmentId, setInvestmentId] = useState<number|null>(null)

  useEffect(() => {
    api.get(`/offerings/${id}/invest`, { signal: abortController.signal })
      .then(response => {
        if (response !== undefined) {
          setInvestors(response.data.data.investors)
          setOffering(response.data.data.offering)

          const hasExistingInvestments = response.data.data.investors.some((investor: { [key: string]: any }) => investor.investments.length > 0)

          setStatus(hasExistingInvestments ? Status.HasExistingInvestments : Status.ReadingIntro)
        }
      })

    return () => abortController.abort()
  }, [id])

  if (offering === null || investors === null) {
    return (
      <AuthLayout>
        <Loading />
      </AuthLayout>
    )
  }

  if (investmentId !== null) {
    return (
      <Navigate
        to={`/investments/${investmentId}/review`}
      />
    )
  }

  if (status === Status.HasExistingInvestments) {
    return (
      <AuthLayout
        headerRight={
          <Link
            to="/offerings"
            className="btn btn-sm btn-outline-primary d-flex flex-row align-items-center"
          >
            <X
              size="12px"
              className="me-2"
            />

            Cancel investment
          </Link>
        }
      >
        <Col
          xs={12}
          md={10}
          lg={9}
          xl={8}
          className="text-center"
        >
          <h1 className="mb-4">Review your investments</h1>

          <p className="text-muted px-5 mb-5">
            You have already attempted or completed investments for this offering. You can choose an existing investment to know or update its status, or create a new one.
          </p>

          <Card>
            <Card.Body className="text-left p-0">
              <ListGroup className="list-group-flush">
                { investors.map(investor => investor.investments.map((investment: { [key: string]: any }) => (
                  <ListGroup.Item
                    className="px-4"
                    key={investment.id}
                  >
                    <Row className="align-items-center">
                      <Col
                        xs={12}
                        md={3}
                        className="overflow-ellipsis"
                      >
                        { investor.name }
                      </Col>

                      <Col
                        xs={12}
                        md={2}
                        className="text-center"
                      >
                        { currencyFormat(investment.amount, investment.currency) }
                      </Col>

                      <Col>
                        { investment.status === 'created' && (
                          <span className="badge bg-secondary-soft fs-5">Generating documents</span>
                        ) }

                        { investment.status === 'prepared' && (
                          <span className="badge bg-secondary-soft fs-5">Review pending</span>
                        ) }

                        { investment.status === 'reviewed' && (
                          <span className="badge bg-warning fs-5">Signature pending</span>
                        ) }

                        { investment.status === 'declined' && (
                          <span className="badge bg-danger-soft fs-5">Signature declined</span>
                        ) }

                        { investment.status === 'signed' && (
                          <span className="badge bg-info-soft fs-5">Payment pending</span>
                        ) }

                        { investment.status === 'paid' && (
                          <span className="badge bg-success-soft fs-5">Paid</span>
                        ) }
                      </Col>

                      <Col
                        xs={12}
                        md={2}
                        className="text-right"
                      >
                        { investment.updated_at }
                      </Col>

                      <Col
                        xs="auto"
                        className="text-right"
                      >
                        { (investment.status === 'created' || investment.status === 'prepared') && (
                          <Link
                            to={`/investments/${investment.id}/review`}
                            className="btn btn-primary btn-sm"
                          >
                            Review
                          </Link>
                        ) }

                        { (investment.status === 'reviewed') && (
                          <Link
                            to={`/investments/${investment.id}/sign`}
                            className="btn btn-primary btn-sm"
                          >
                            Sign
                          </Link>
                        ) }

                        { (investment.status === 'signed') && (
                          <Link
                            to={`/investments/${investment.id}/transfer`}
                            className="btn btn-primary btn-sm"
                          >
                            View
                          </Link>
                        ) }

                        { (investment.status === 'paid' || investment.status === 'declined') && (
                          <button
                            className="btn btn-primary btn-sm disabled"
                          >
                            View
                          </button>
                        ) }
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))) }
              </ListGroup>
            </Card.Body>
          </Card>

          <button
            className="btn btn-primary px-5"
            onClick={() => setStatus(Status.ChoosingDetails)}
          >
            Create a new investment
          </button>
        </Col>
      </AuthLayout>
    )
  }

  if (status === Status.ReadingIntro) {
    return (
      <AuthLayout
        headerRight={
          <Link
            to="/offerings"
            className="btn btn-sm btn-outline-primary d-flex flex-row align-items-center"
          >
            <X
              size="12px"
              className="me-2"
            />

            Cancel investment
          </Link>
        }
      >
        <Col
          xs={12}
          className="text-center"
        >
          <h1 className="mb-4">How to invest</h1>

          <p className="text-muted mb-5 pb-3">
            Before proceeding to invest, learn how investing in our offerings work in three simple steps.
          </p>
        </Col>

        <Col
          xs={12}
          md={6}
          lg={4}
          xl={3}
          className="text-center"
        >
          <div className="avatar mb-4">
            <div className="avatar-title rounded-circle bg-info-soft fs-lg text-primary">
            1
            </div>
          </div>

          <h3 className="text-center">
            Choose your investment details
          </h3>

          <p className="text-muted">
            Select the investor entity, the amount and the payment method.
          </p>
        </Col>

        <Col
          xs={12}
          md={6}
          lg={4}
          xl={3}
          className="text-center"
        >
          <div className="avatar mb-4">
            <div className="avatar-title rounded-circle bg-info-soft fs-lg text-primary">
              2
            </div>
          </div>

          <h3 className="text-center">
            Review and sign the agreement
          </h3>

          <p className="text-muted">
            Review all the terms and conditions and confirm your information.
          </p>
        </Col>

        <Col
          xs={12}
          md={6}
          lg={4}
          xl={3}
          className="text-center"
        >
          <div className="avatar mb-4">
            <div className="avatar-title rounded-circle bg-info-soft fs-lg text-primary">
              3
            </div>
          </div>

          <h3 className="text-center">
            Transfer the funds and... done!
          </h3>

          <p className="text-muted">
            You will receive the transfer details after you sign your documents.
          </p>
        </Col>

        <Col
          xs={12}
          className="text-center mt-5"
        >
          <button
            className="btn btn-primary px-5"
            onClick={() => setStatus(Status.ChoosingDetails)}
          >
            Continue
          </button>
        </Col>
      </AuthLayout>
    )
  }

  if (investors.length === 0) {
    return (
      <AuthLayout
        headerRight={
          <Link
            to="/offerings"
            className="btn btn-sm btn-outline-primary d-flex flex-row align-items-center"
          >
            <X
              size="12px"
              className="me-2"
            />

          Back to Offerings
          </Link>
        }
      >
        <Col
          xs={12}
          md={10}
          lg={8}
          xl={6}
        >
          <h1 className="text-center mb-4">
            <AlertTriangle size="36px" />
          </h1>

          <h2 className="text-center mb-4">
            Create an investor account
          </h2>

          <p className="text-center text-muted mb-5">
            You are currently not eligible to invest because you have not set up an investor account either for yourself or for a legal entity.
          </p>

          <p className="text-center mb-0">
            <Link
              to="/offerings"
              className="btn btn-outline-primary px-5 me-4"
            >
              Back to Offerings
            </Link>

            <Link
              to="/account/investor/new"
              className="btn btn-primary px-5"
            >
              Create an investor account
            </Link>
          </p>
        </Col>
      </AuthLayout>
    )
  }

  return (
    <AuthLayout
      headerRight={
        <Link
          to="/offerings"
          className="btn btn-sm btn-outline-primary d-flex flex-row align-items-center"
        >
          <X
            size="12px"
            className="me-2"
          />

          Cancel investment
        </Link>
      }
    >
      <Formik
        initialValues={{
          offering_id: id,
          investor_id: `${investors[0].id}`,
          amount: '',
          method: 'bank_transfer',
        }}
        onSubmit={(values) => {
          setStatus(Status.SendingDetails)

          api.post('/investments', values, { signal: abortController.signal })
            .then(response => {
              if (response !== undefined) {
                setInvestmentId(response.data.data.id)
              }
            })
            .catch(err => {
              if (axios.isAxiosError(err)) {
                if (err.response?.data?.errors !== undefined) {
                  setErrors(err.response.data.errors)
                }
              }
    
              setStatus(Status.ChoosingDetails)
            })
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Row>
              <Col
                xs={12}
                className="mb-3"
              >
                <h1 className="mt-4 ms-2 mb-4">
                  Invest in: &nbsp;{ offering.name }
                </h1>

                <ol className="breadcrumb ms-2 mb-4">
                  <li className="breadcrumb-item text-primary">1. Investment details</li>

                  <li className="breadcrumb-item text-muted">2. Review and sign agreement</li>

                  <li className="breadcrumb-item text-muted">3. Transfer funds</li>
                </ol>
              </Col>
            </Row>

            <Row>
              <Col
                xs={12}
                lg={5}
              >
                <Card>
                  <Card.Header>
                    <h4 className="card-header-title">
                      Offering summary
                    </h4>
                  </Card.Header>
              
                  <Card.Body>
                    <p className="text-center mb-3">
                      <img
                        src={offering.images[0]?.preview ?? null}
                        className="mx-auto mb-3 rounded"
                        alt={offering.name}
                        style={{
                          maxHeight: '200px'
                        }}
                      />
                    </p>

                    <p className="small text-justify">
                      { offering.description }
                    </p>

                    <hr className="my-4" />

                    <div className="mb-4 text-center">
                      <p className="small text-muted mb-1">
                        Total Investment Offering
                      </p>

                      <p
                        className="text-info mb-0"
                        style={{ fontSize: '24px' }}
                      >
                        { currencyFormat(offering.amount, offering.currency) }
                      </p>
                    </div>

                    <div className="my-2">
                      <p className="small text-muted mb-3">
                        Key Stats
                      </p>

                      <div className="small d-flex flex-row justify-content-between mb-3">
                        <b>Farmland</b>

                        <span>{ offering.meta.farmland }</span>
                      </div>

                      <div className="small d-flex flex-row justify-content-between mb-3">
                        <b>Productive Area</b>

                        <span>{ offering.meta.productive_area }</span>
                      </div>

                      <div className="small d-flex flex-row justify-content-between mb-3">
                        <b>Gross Cash Yield</b>

                        <span>{ offering.meta.yield }</span>
                      </div>

                      <div className="small d-flex flex-row justify-content-between mb-0">
                        <b>Net Anual IRR</b>

                        <span>{ offering.meta.irr }</span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            
              <Col
                xs={12}
                lg={7}
              >
                <Card>
                  <Card.Header>
                    <h4 className="card-header-title">
                      Investment amount
                    </h4>
                  </Card.Header>
            
                  <Card.Body className="pb-3">
                    <p className="text-muted">
                      Select the amount you would like to invest in this offering.
                    </p>
                      
                    <InputGroup
                      size="lg"
                      className={errors?.amount ? 'input-group-prepend mb-2 is-invalid' : 'input-group-prepend mb-2'}
                    >
                      <InputGroup.Text>
                        { currencySymbol(offering.currency) }
                      </InputGroup.Text>

                      <NumberFormat
                        className={errors?.amount ? 'form-control is-invalid' : 'form-control'}
                        id="amount"
                        name="amount"
                        placeholder="Amount"
                        inputMode="numeric"
                        isNumericString={true}
                        thousandSeparator={true}
                        value={values.amount}
                        onValueChange={val => setFieldValue('amount', val.floatValue)}
                      />
                    </InputGroup>

                    {errors?.amount && (
                      <ReactForm.Control.Feedback type="invalid">
                        {errors?.amount}
                      </ReactForm.Control.Feedback>
                    )}

                    <p className="small text-right mb-2">
                      Minimum: { currencyFormat(100000, offering.currency) } | Maximum: { currencyFormat(offering.remaining_amount, offering.currency) }
                    </p>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Header>
                    <h4 className="card-header-title">
                      Investor entity
                    </h4>
                  </Card.Header>
              
                  <Card.Body>
                    <p className="text-muted">
                      Choose the entity on behalf of which you would like to invest. If you would like to use another investor entity, please <Link to="/account/investor/new">create a new investment account</Link>.
                    </p>

                    { investors.map(investor => (
                      <div
                        className="form-check ms-1 mt-3 mb-2"
                        key={investor.id}
                      >
                        <Field
                          type="radio"
                          className="form-check-input mt-2 me-3"
                          name="investor_id"
                          id={`investor_id_${investor.id}`}
                          value={`${investor.id}`}
                        />

                        <label
                          className="form-check-label"
                          htmlFor={`investor_id_${investor.id}`}
                          style={{ lineHeight: '26px' }}
                        >
                          { investor.name }<br />

                          { investor.type === 'natural' 
                            ? (
                              <span className="text-muted small">
                                Natural person &nbsp;·&nbsp; { countryName(investor.country) } &nbsp;·&nbsp; Document number: { investor.document_number }
                              </span>
                            ) : (
                              <span className="text-muted small">
                                Legal entity &nbsp;·&nbsp; { countryName(investor.country) } &nbsp;·&nbsp; Company number: { investor.entity_number }
                              </span>
                            ) }
                        </label>
                      </div>
                    )) }
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Header>
                    <h4 className="card-header-title">
                      Payment method
                    </h4>
                  </Card.Header>
            
                  <Card.Body>
                    <p className="text-muted">
                      Choose the method you will use to transfer the funds to the investment vehicle.
                    </p>

                    <div className="form-check ms-1 mb-2">
                      <Field
                        type="radio"
                        className="form-check-input mt-2 me-3"
                        name="method"
                        id="method_bank_transfer"
                        value="bank_transfer"
                      />

                      <label
                        className="form-check-label"
                        htmlFor="method_bank_transfer"
                        style={{ lineHeight: '26px' }}
                      >
                        SEPA bank transfer<br />

                        <span className="text-muted small">
                          You will receive instructions after signing the agreement.
                        </span>
                      </label>
                    </div>
                  </Card.Body>
                </Card>

                <LoadingButton 
                  busy={status === Status.SendingDetails}
                  type="submit"
                  size="lg"
                  className="d-block ms-auto px-5"
                  disabled={status === Status.SendingDetails}
                >
                  Continue
                </LoadingButton>
              </Col>
                    
            </Row>
          </Form>
        ) }
      </Formik>
    </AuthLayout>
  )
}
