import SumsubWebSdk from '@sumsub/websdk-react'
import axios from 'axios'
import { Field, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Card, Col, ListGroup, Row } from 'react-bootstrap'
import { ArrowLeft, User, X } from 'react-feather'
import NumberFormat from 'react-number-format'
import { Link } from 'react-router-dom'

import ErrorFeedback from '../components/ErrorFeedback'
import LoadingButton from '../components/LoadingButton'
import api from '../utils/api'

import Loading from './aux/Loading'
import AuthLayout from './layouts/AuthLayout'

enum Status {
  CheckingIfVerified,
  NotVerified,
  LoadingInvestors,
  ShowingInvestors,
  ReadingIntro,
  CompletingSumsubSdk,
}

export default function AccountInvestorNewLegal() {
  const abortController = new AbortController()

  const [status, setStatus] = useState<Status>(Status.CheckingIfVerified)
  const [accessToken, setAccessToken] = useState<string>('')
  const [user, setUser] = useState<{ [key: string]: string }>({})
  const [investors, setInvestors] = useState<{[key: string]: any}[]>([])

  useEffect(() => {
    if (status === Status.CheckingIfVerified) {
      api.get('/user', { signal: abortController.signal })
        .then(response => {
          if (response !== undefined) {
            if (response.data.data.kyc_status === 'verified') {
              setUser(response.data.data)
              setStatus(Status.LoadingInvestors)
            } else {
              setStatus(Status.NotVerified)
            }
          }
        })
    }

    if (status === Status.LoadingInvestors) {
      api.get('/investors/legal', { signal: abortController.signal })
        .then(response => {
          if (response !== undefined) {
            if (response.data.data.length > 0) {
              setInvestors(response.data.data)
              setStatus(Status.ShowingInvestors)
            } else {
              setStatus(Status.ReadingIntro)
            }
          }
        })
    }

    return () => abortController.abort()
  }, [status])

  if (status === Status.NotVerified) {
    return (
      <AuthLayout
        headerRight={
          <Link
            to="/account/investor/new"
            className="btn btn-sm btn-outline-primary d-flex flex-row align-items-center"
          >
            <ArrowLeft
              size="12px"
              className="me-2"
            />

            Previous step
          </Link>
        }
      >
        <Col
          xs={12}
          md={10}
          lg={8}
          xl={6}
        >
          <h1 className="text-center mb-4">
            <User size="36px" />
          </h1>

          <h2 className="text-center mb-4">
            Verify your identity
          </h2>

          <p className="text-center text-muted mb-5">
            Before investing, we need you to verify your identity and provide some documentation. Click the button below to get started.
          </p>

          <p className="text-center mb-0">
            <Link
              to="/account/user/verify?investor=legal"
              className="btn btn-primary w-50"
            >
              Start verifying
            </Link>
          </p>
        </Col>
      </AuthLayout>
    )
  }

  if (status === Status.CheckingIfVerified || status === Status.LoadingInvestors) {
    return <Loading />
  }
  
  return (
    <AuthLayout
      headerRight={
        <Link
          to="/account/investor/new"
          className="btn btn-sm btn-outline-primary d-flex flex-row align-items-center"
        >
          <X
            size="12px"
            className="me-2"
          />

          Close
        </Link>
      }
    >
      <Col
        xs={12}
        sm={12}
        md={10}
        lg={9}
        xl={8}
      >
        <h1 className="text-center mb-5">
          Investing from a legal entity
        </h1>

        { (status === Status.ShowingInvestors) && (
          <>
            <p className="text-center text-muted px-5 mb-5">
              You have already opened or attempted to open investor accounts for one or more legal entities. Pending applications will be reviewed within 72 hours after being filed.
            </p>

            <Card>
              <Card.Body className="text-left p-0">
                <ListGroup className="list-group-flush">
                  { investors.map((investor, index) => (
                    <ListGroup.Item
                      className="px-4"
                      key={investor.id}
                    >
                      <Row className="align-items-center">
                        <Col
                          xs={12}
                          md={6}
                          className="overflow-ellipsis d-flex align-items-center"
                        >
                          { investor.entity_name }

                          { investor.kyc_status === 'verified' && (
                            <span className="badge bg-success-soft fs-5 ms-3">Verified</span>
                          ) }

                          { investor.kyc_status === 'pending' && (
                            <span className="badge bg-danger-soft fs-5 ms-3">Pending</span>
                          ) }
                        </Col>

                        <Col className="text-right">
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={async () => {
                              const response = await api.post('/services/sumsub/token', { legal_entity_index: index + 1 }, { signal: abortController.signal })

                              if (response !== undefined) {
                                setAccessToken(response.data.data.token)
                                setStatus(Status.CompletingSumsubSdk)
                              }
                            }}
                            disabled={investor.kyc_status === 'verified'}
                          >
                            View status
                          </button>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )) }
                </ListGroup>
              </Card.Body>
            </Card>

            { investors.filter(investor => investor.kyc_status === 'pending').length === 0 && (
              <p className="text-center">
                <button
                  className="btn btn-primary px-5"
                  onClick={() => setStatus(Status.ReadingIntro)}
                >
                  Invest from a new entity
                </button>
              </p>
            ) }
          </>
        ) }

        { (status === Status.ReadingIntro) && (
          <Card>
            <Card.Body>
              <Formik
                initialValues={{
                  legal_entity_index: investors.length + 1,
                  position: '',
                  appears_in_register: '',
                  pct_ownership: '',
                  is_ubo: '',
                }}
              
                onSubmit={async (values, actions) => {
                  try {
                    const response = await api.post('/services/sumsub/token', values, { signal: abortController.signal })

                    if (response !== undefined) {
                      setAccessToken(response.data.data.token)
                      setStatus(Status.CompletingSumsubSdk)
                    }
                  }
                  catch (err) {
                    if (axios.isAxiosError(err) && err.response?.data?.errors !== undefined) {
                      actions.setErrors(err.response.data.errors)
                    }
                  }
                }}
              >
                { ({ values, errors, setFieldValue, isSubmitting, submitForm }) => (
                  <>
                    <p>
                      We are required to complete a Know Your Business (KYB) process to identify the legal entity you will be investing on behalf of. This information will be collected by our partner Sumsub, encrypted and securely transmitted to our servers according to maximum compliance standards and RGPD.
                    </p>

                    <p>
                      You will be asked to upload documentation about the legal entity and its ultimate beneficial owners.
                    </p>

                    <p>
                      Before proceeding to the KYB form, we need to know about your relationship with the entity:
                    </p>

                    <ol>
                      <li className="ps-2">
                        <p className="mb-3">
                          Are you ({ `${user.first_name} ${user.last_name}` }) a legal representative of the entity?
                        </p>

                        <p className="mb-4">
                          <Field
                            as="select"
                            name="position"
                            className={errors.position ? 'form-control w-75 is-invalid' : 'form-control w-75'}
                          >
                            <option value="">Please select an option...</option>

                            <option value="director">Yes, I am a director / board member</option>

                            <option value="other">Yes, I am another type of legal representative</option>
                          </Field>

                          <ErrorFeedback error={errors.position} />
                        </p>
                      </li>

                      <li className="ps-2">
                        <p className="mb-3">
                          Do you ({ `${user.first_name} ${user.last_name}` }) appear as a legal representative or managing officer within the records of the official company register to which your entity belongs?
                        </p>

                        <p className="mb-3">
                          (In some countries, this information is not publicly available. Please answer positively only if you are sure this information is publicly available at the website of the official company register.)
                        </p>

                        <p className={values.appears_in_register === 'no' ? 'mb-3' : 'mb-4'}>
                          <Field
                            as="select"
                            name="appears_in_register"
                            className={errors.appears_in_register ? 'form-control w-75 is-invalid' : 'form-control w-75'}
                          >
                            <option value="">Please select an option...</option>

                            <option value="yes">Yes, I appear in the official company register</option>

                            <option value="no">No, I will upload a valid proof of authority</option>
                          </Field>

                          <ErrorFeedback error={errors.appears_in_register} />
                        </p>

                        {values.appears_in_register === 'no' && (
                          <p className="mb-4 text-danger">
                            <b>Important:</b> You will need to upload a valid proof of authority (e.g. a power of attorney) by clicking on your name in the "Representatives" tab of the KYB form.
                          </p>
                        ) }
                      </li>

                      <li className="ps-2">
                        <p className="mb-3">
                          What percentage of ownership of the entity do you ({ `${user.first_name} ${user.last_name}` }) have?
                        </p>

                        <p className="mb-4">
                          <NumberFormat
                            className={errors.pct_ownership ? 'form-control w-75 is-invalid' : 'form-control w-75'}
                            id="pct_ownership"
                            name="pct_ownership"
                            placeholder="0.00%"
                            max={100}
                            min={0}
                            suffix={'%'}
                            decimalScale={2}
                            inputMode="numeric"
                            isNumericString={true}
                            thousandSeparator={true}
                            value={values.pct_ownership}
                            onValueChange={val => setFieldValue('pct_ownership', val.floatValue)}
                          />

                          <ErrorFeedback error={errors.pct_ownership} />
                        </p>
                      </li>

                      <li className="ps-2">
                        <p className="mb-3">
                          An ultimate beneficial owner (UBO) is defined as any natural person who ultimately owns or controls a legal entity. This includes (but is not limited to) individuals directly or indirectly holding 25% or more of the shares of a company and, in some cases, its directors or board members.
                        </p>

                        <p className="mb-3">
                          Are you ({ `${user.first_name} ${user.last_name}` }) an ultimate beneficial owner of the entity?
                        </p>

                        <p className="mb-4">
                          <Field
                            as="select"
                            name="is_ubo"
                            className={errors.is_ubo ? 'form-control w-75 is-invalid' : 'form-control w-75'}
                          >
                            <option value="">Please select an option...</option>

                            <option value="yes">Yes, I am an UBO of the entity</option>

                            <option value="no">No, I am not a UBO of the entity</option>
                          </Field>

                          <ErrorFeedback error={errors.is_ubo} />
                        </p>
                      </li>
                    </ol>

                    <p className="text-center mt-4 mb-0">
                      <LoadingButton
                        busy={isSubmitting}
                        className="btn btn-primary px-5"
                        onClick={submitForm}
                      >
                        Start KYB for a new legal entity
                      </LoadingButton>
                    </p>
                  </>
                ) }
              </Formik>
            </Card.Body>
          </Card>
        ) }

        { status === Status.CompletingSumsubSdk && (
          <SumsubWebSdk
            accessToken={accessToken}
            expirationHandler={async () => {
              const response = await api.get('/services/sumsub/token?type=company', { signal: abortController.signal })

              if (response !== undefined) {
                return Promise.resolve(response.data.data.token)
              } else {
                return Promise.reject()
              }
            }}
            config={{ lang: 'en' }}
            onMessage={
              (type, payload) => {
                if (type === 'idCheck.applicantStatus' && 'reviewStatus' in payload) {
                  api.post('/services/sumsub/sync_legal', { legal_entity_index: 1 }, { signal: abortController.signal })
                }
              }
            }
          />
        ) }
      </Col>
    </AuthLayout>
  )
}
