import { useEffect, useState } from 'react'
import { Alert, Card, Col, ListGroup, Row } from 'react-bootstrap'
import { Check, CheckCircle, } from 'react-feather'
import { Link, useParams } from 'react-router-dom'

import { currencyFormat } from '../helpers'
import api from '../utils/api'

import Loading from './aux/Loading'
import AuthLayout from './layouts/AuthLayout'

export default function InvestmentTransfer() {
  const { id } = useParams()
  const [investment, setInvestment] = useState<{ [key: string]: any }|null>(null)

  useEffect(() => {
    const abortController = new AbortController()

    api.get(`/investments/${id}/transfer`, { signal: abortController.signal })
      .then(response => {
        if (response !== undefined) {
          setInvestment(response.data.data)
        }
      })

    return () => abortController.abort()
  }, [])

  if (investment === null) {
    return (
      <AuthLayout>
        <Loading />
      </AuthLayout>
    )
  }

  return (
    <AuthLayout
      headerRight={
        <Link
          to="/offerings"
          className="btn btn-sm btn-outline-primary d-flex flex-row align-items-center me-3"
        >
          <Check
            size="12px"
            className="me-2"
          />

            Done
        </Link>
      }
    >
      <Col
        xs={12}
        md={10}
        lg={8}
      >
        <h1 className="mt-4 ms-2 mb-4">
          Invest in: &nbsp;{ investment.offering.name }
        </h1>

        <ol className="breadcrumb ms-2 mb-4">
          <li className="breadcrumb-item text-muted">1. Investment details</li>

          <li className="breadcrumb-item text-muted">2. Review and sign agreement</li>

          <li className="breadcrumb-item text-primary">3. Transfer funds</li>
        </ol>
      
        <Alert variant="success">
          <CheckCircle
            size="16px"
            className="me-3"
          /> 

          We have received your signature. Please transfer funds to complete your investment.
        </Alert>

        <Card>
          <Card.Header>
            <h4 className="card-header-title">
              Transfer funds
            </h4>
          </Card.Header>

          <Card.Body className="">
            <p className="mb-4">
              In order to complete your investment, please make a SEPA transfer with the following details so funds are transferred to the investment vehicle:
            </p>

            <div className="mb-4 mx-3">
              <ListGroup className="list-group-flush">
                <ListGroup.Item>
                  <Row>
                    <Col xs={4}>
                      <b>Account IBAN</b>
                    </Col>

                    <Col xs={8}>
                      LU05 1204 1239 4905 3805 3849
                    </Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col xs={4}>
                      <b>Account SWIFT / BIC</b>
                    </Col>

                    <Col xs={8}>
                      ALPHLUL1XXX
                    </Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col xs={4}>
                      <b>Amount</b>
                    </Col>

                    <Col xs={8}>
                      { currencyFormat(investment.amount, investment.currency) }
                    </Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col xs={4}>
                      <b>Reference</b>
                    </Col>

                    <Col xs={8}>
                      12395F238H
                    </Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col xs={4}>
                      <b>Beneficiary</b>
                    </Col>

                    <Col xs={8}>
                      Bageera S.A. S.V.
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </div>

            <p className="mb-2">
              We will send you an email and update the investment status as soon as the payment is received.
            </p>
          </Card.Body>
        </Card>
      </Col>
    </AuthLayout>
  )
}
