import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { Navigate, Routes, Route, useLocation } from 'react-router-dom'

import AuthComponent from './components/auth/AuthComponent'
import api from './utils/api'
import { useAppSelector, useAppDispatch } from './utils/hooks'
import { selectLoggedIn, fetchStatus } from './utils/store/auth'
import { selectStatus, setStatusCode } from './utils/store/status'
import AccountInvestor from './views/AccountInvestor'
import AccountInvestorNew from './views/AccountInvestorNew'
import AccountInvestorNewLegal from './views/AccountInvestorNewLegal'
import AccountInvestorNewNatural from './views/AccountInvestorNewNatural'
import AccountOverview from './views/AccountOverview'
import AccountTransactions from './views/AccountTransactions'
import AccountUser from './views/AccountUser'
import AccountUserVerify from './views/AccountUserVerify'
import InvestmentReview from './views/InvestmentReview'
import InvestmentSign from './views/InvestmentSign'
import InvestmentSigned from './views/InvestmentSigned'
import InvestmentTransfer from './views/InvestmentTransfer'
import OfferingInvest from './views/OfferingInvest'
import Offerings from './views/Offerings'
import Portfolio from './views/Portfolio'
import RegisterInterest from './views/RegisterInterest'
import Welcome from './views/Welcome'
import Loading from './views/aux/Loading'
import NotAuthorized from './views/errors/NotAuthorized'
import NotFound from './views/errors/NotFound'
import AccountLayout from './views/layouts/AccountLayout'
import PrivateLayout from './views/layouts/PrivateLayout'

export default function App() {
  const dispatch = useAppDispatch()
  const location = useLocation()

  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        getAccessTokenSilently({
          audience: 'https://api.bageera.co'
        })
          .then(accessToken => {
            api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
            dispatch(fetchStatus())
          })
      } else {
        dispatch(fetchStatus())
      }
    }
  }, [isLoading])

  useEffect(() => {
    dispatch(setStatusCode(200))
  }, [location.key])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  const loggedIn = useAppSelector(selectLoggedIn)
  const status = useAppSelector(selectStatus)

  if (isLoading || loggedIn === null || status.code === -1) {
    return (
      <Loading absolute={true} />
    )
  }

  if (status.code === 403) {
    return (
      <NotAuthorized />
    )
  }

  if (status.code === 404) {
    return (
      <NotFound />
    )
  }

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<Navigate to="/offerings" />}
        />

        <Route
          path="/"
          element={
            <PrivateLayout />
          }
        >
          <Route
            path="offerings"
            element={
              <AuthComponent component={<Offerings />} />
            }
          />

          <Route
            path="portfolio"
            element={
              <AuthComponent component={<Portfolio />} />
            }
          />

          <Route
            path="account"
            element={
              <AuthComponent component={<AccountLayout />} />
            }
          >
            <Route
              path=""
              element={
                <AccountOverview />
              }
            />

            <Route
              path="transactions"
              element={
                <AccountTransactions />
              }
            />

            <Route
              path="investor"
              element={
                <AccountInvestor />
              }
            />
            
            <Route
              path="user"
              element={
                <AccountUser />
              }
            />
          </Route>
        </Route>

        <Route
          path="/account/investor/new"
          element={
            <AuthComponent component={<AccountInvestorNew />} />
          }
        />

        <Route
          path="/account/investor/new/natural"
          element={
            <AuthComponent component={<AccountInvestorNewNatural />} />
          }
        />

        <Route
          path="/account/investor/new/legal"
          element={
            <AuthComponent component={<AccountInvestorNewLegal />} />
          }
        />

        <Route
          path="/account/user/verify"
          element={
            <AuthComponent component={<AccountUserVerify />} />
          }
        />

        <Route
          path="/investments/:id/review"
          element={
            <AuthComponent component={<InvestmentReview />} />
          }
        />

        <Route
          path="/investments/:id/sign"
          element={
            <AuthComponent component={<InvestmentSign />} />
          }
        />

        <Route
          path="/investments/:id/signed"
          element={
            <AuthComponent component={<InvestmentSigned />} />
          }
        />

        <Route
          path="/investments/:id/transfer"
          element={
            <AuthComponent component={<InvestmentTransfer />} />
          }
        />

        <Route
          path="/offerings/:id/invest"
          element={
            <AuthComponent component={<OfferingInvest />} />
          }
        />

        <Route
          path="/offerings/:id/register"
          element={
            <AuthComponent component={<RegisterInterest />} />
          }
        />

        <Route
          path="/welcome"
          element={
            <AuthComponent component={<Welcome />} />
          }
        />

        <Route
          path="*"
          element={<NotFound />}
        />
      </Routes>

      <Modal
        show={status.code === 401}
        centered
        backdrop="static"
      >
        <Modal.Body className="text-center py-5">
          <h2 className="mb-4">
            Session expired
          </h2>

          <p className="mb-4">
            Your session has expired after a period of inactivity.<br />
            Please log in again to access the platform.
          </p>

          <p className="mb-0">
            <button
              className="btn btn-primary px-5"
              onClick={() => window.location.reload()}
            >
              Log in again
            </button>
          </p>
        </Modal.Body>
      </Modal>
    </>
  )
}
