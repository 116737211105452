import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import api from '../api'

import type { RootState } from './index'

export interface CurrentUser {
  id: number,
  name: string,
  email: string,
  investors: { id: number, name: string }[],
  passed_welcome: boolean
}

interface AuthState {
  loggedIn: boolean|null,
  user: CurrentUser|null
}

const initialState: AuthState = {
  loggedIn: null,
  user: null
}

export const fetchStatus = createAsyncThunk('auth/fetchStatus', async () => {
  const response = await api.get('/auth/user')
  
  return response.data.data.user
})

export const authSlice = createSlice({
  name: 'auth',

  initialState,

  reducers: {
    login: (state, action: PayloadAction<CurrentUser|null>) => {
      state.loggedIn = true
      state.user = action.payload
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchStatus.pending, (state, action) => {
        //
      })
      .addCase(fetchStatus.fulfilled, (state, action) => {
        state.loggedIn = action.payload !== null
        state.user = action.payload
      })
      .addCase(fetchStatus.rejected, (state, action) => {
        //
      })
  }
})

export const { login } = authSlice.actions

export const selectLoggedIn = (state: RootState) => state.auth.loggedIn
export const selectUser = (state: RootState) => state.auth.user

export default authSlice.reducer
