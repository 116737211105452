import axios from 'axios'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Card, Col, InputGroup, Row, Form as ReactForm } from 'react-bootstrap'
import { Check, X } from 'react-feather'
import NumberFormat from 'react-number-format'
import { Link, useParams } from 'react-router-dom'

import LoadingButton from '../components/LoadingButton'
import { currencySymbol } from '../helpers'
import api from '../utils/api'

import Loading from './aux/Loading'
import AuthLayout from './layouts/AuthLayout'

export default function RegisterInterest() {
  const abortController = new AbortController()

  const { id } = useParams()
  const [offering, setOffering] = useState<{ [key: string]: any }|null>(null)

  const [status, setStatus] = useState<string>('ready')
  const [errors, setErrors] = useState<{ [key: string]: any }>({})

  useEffect(() => {
    api.get(`/offerings/${id}`, { signal: abortController.signal })
      .then(response => {
        if (response !== undefined) {
          setOffering(response.data.data)
        }
      })

    return () => abortController.abort()
  }, [id])

  if (offering === null) {
    return (
      <AuthLayout>
        <Loading />
      </AuthLayout>
    )
  }

  if (status === 'done') {
    return (
      <AuthLayout>
        <Col
          xs={12}
          sm={12}
          md={10}
          lg={8}
          xl={6}
        >
          <Card>
            <Card.Body className="p-4 pt-5 p-sm-5">
              <h1>
                <Check size="24px" />&nbsp; Interest registered
              </h1>

              <p className="text-muted">
                You have successfully registered your interest in the offering <b>{ offering.name }</b>.
              </p>
            
              <p className="text-muted mb-4">
                We will keep you updated about the progress of this offering and let you know when it is open for investments.
              </p>
                    
              <Link
                to="/offerings"
                className="btn btn-primary btn-lg w-100"
              >
                Back to Offerings
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </AuthLayout>
    )
  }

  return (
    <AuthLayout
      headerRight={
        <Link
          to="/offerings"
          className="btn btn-sm btn-outline-primary d-flex flex-row align-items-center"
        >
          <X
            size="12px"
            className="me-2"
          />

          Cancel registration
        </Link>
      }
    >
      <Row>
        <Col
          xs={12}
          className="mb-3"
        >
          <h1 className="mt-5 mb-5">
          Register interest in: &nbsp;{ offering.name }
          </h1>
        </Col>
      </Row>

      <Row className="justify-content-md-center mx-auto">
        <Col
          xs={12}
          lg={4}
          className="pe-5 text-center me-5"
        >
          <div>
            <img
              src={offering.images[0]?.preview ?? null}
              className="w-100 mx-auto mb-5 rounded"
              alt={offering.name}
            />

            <p className="text-justify">
              { offering.description }
            </p>
          </div>
        </Col>
        
        <Col
          xs={12}
          lg={6}
        >
          <Formik
            initialValues={{
              amount: '',
            }}
            onSubmit={values => {
              setStatus('loading')

              api.post(`/offerings/${id}/register`, values, { signal: abortController.signal })
                .then(response => {
                  if (response !== undefined) {
                    setStatus('done')
                  }
                })
                .catch(err => {
                  if (axios.isAxiosError(err)) {
                    if (err.response?.data?.errors !== undefined) {
                      setErrors(err.response.data.errors)
                    }
                  }

                  setStatus('ready')
                })
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Card>
                  <Card.Header>
                    <h4 className="card-header-title">
                      Investment amount
                    </h4>
                  </Card.Header>
        
                  <Card.Body>
                    <InputGroup
                      size="lg"
                      className={errors?.amount ? 'input-group-prepend mb-2 is-invalid' : 'input-group-prepend mb-2'}
                    >
                      <InputGroup.Text>
                        { currencySymbol(offering.currency) }
                      </InputGroup.Text>

                      <NumberFormat
                        className={errors?.amount ? 'form-control is-invalid' : 'form-control'}
                        id="amount"
                        name="amount"
                        placeholder="Amount"
                        inputMode="numeric"
                        isNumericString={true}
                        thousandSeparator={true}
                        value={values.amount}
                        onValueChange={val => setFieldValue('amount', val.floatValue)}
                      />
                    </InputGroup>

                    {errors?.amount && (
                      <ReactForm.Control.Feedback type="invalid">
                        {errors?.amount}
                      </ReactForm.Control.Feedback>
                    )}
                  </Card.Body>
                </Card>

                <LoadingButton 
                  busy={status === 'loading'}
                  type="submit"
                  size="lg"
                  className="d-block ms-auto px-5"
                  disabled={status === 'loading'}
                >
                  Register your interest
                </LoadingButton>
              </Form>
            ) }
          </Formik>
        </Col>
      </Row>
    </AuthLayout>
  )
}
