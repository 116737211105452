import { Field, FieldProps } from 'formik'
import React, { HTMLProps } from 'react'

import FloatingLabeledFieldWrapper from './FloatingLabeledFieldWrapper'

interface FormGroupFieldProps {
  name: string,
  type?: string,
  label: string,
  errors?: {[key: string]: any},
  divProps?: HTMLProps<HTMLDivElement>,
  fieldProps?: FieldProps,
  showErrors?: boolean,
  children?: React.ReactNode
}

export default function FloatingLabeledField({name, type, label, errors, divProps, fieldProps, children}: FormGroupFieldProps) {
  return (
    <FloatingLabeledFieldWrapper
      name={name}
      label={label}
      errors={errors}
      divProps={divProps}
    >
      <Field
        className={`form-control ${(errors?.[name] ? 'is-invalid' : '')}`}
        id={name}
        name={name}
        type={type || 'text'}
        placeholder={label}
        {...fieldProps}
      >
        {children}
      </Field>
    </FloatingLabeledFieldWrapper>
  )
}
