import { useEffect, useState } from 'react'
import { Card, Col, ListGroup, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import KycAlert from '../components/KycAlert'
import { countryName, currencyFormat } from '../helpers'
import api from '../utils/api'

import Loading from './aux/Loading'

export default function AccountOverview() {
  const [data, setData] = useState<{ [key: string]: any }|null>(null)

  useEffect(() => {
    const abortController = new AbortController()

    api.get('/user/account', { signal: abortController.signal })
      .then(response => {
        if (response !== undefined) {
          setData(response.data.data)
        }
      })

    return () => abortController.abort()
  }, [])

  if (data === null) {
    return (
      <Loading />
    )
  }

  return (
    <>
      <h2 className="ms-2 mb-3">
        Account overview
      </h2>

      <p className="text-muted ms-2 mb-4">
        This is a summary of your account.
      </p>

      { data.investors.length === 0 && <KycAlert /> }

      <Card>
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h4 className="card-header-title">
                Latest transactions
              </h4>
            </Col>

            <Col xs="auto">
              <Link
                to="/account/transactions"
                className="btn btn-outline-primary btn-sm"
              >
                See more &raquo;
              </Link>
            </Col>
          </Row>
        </Card.Header>

        <Card.Body>
          { data.investors.length === 0 && (
            <>
              {/* <Col
                xs={12}
                md={4}
              >
                <p className="small text-muted mb-2">Available funds</p>
                
                <p className="h2 mb-0">{ currencyFormat(0, 'EUR', true) }</p>
              </Col> */}

              <p className="text-center small mb-0">
                No transactions to show.
              </p>
            </>
          ) }

          { data.investors.map((investor: { [key: string]: any }, index: number) => {
            return (
              <div key={investor.id}>
                { data.investors.length > 1 && (
                  <p className="small font-weight-bold">
                    Investor {index+1}: { investor.name }
                  </p>
                ) }
              
                <Row className={index < data.investors.length-1 ? 'mb-4' : 'mb-0'}>
                  <Col
                    xs={12}
                  >
                    { investor.transactions.length === 0 && (
                      <p className="text-center small mb-1">
                        No transactions to show.
                      </p>
                    ) }
                    
                    { investor.transactions.map((investment: { [key: string]: any }, index: number) => (
                      <Row
                        key={investment.id}
                        className={index < investor.transactions.length-1 ? 'align-items-center mb-3' : 'align-items-center mb-1'}
                      >
                        <Col
                          xs={12}
                          md={4}
                          className="overflow-ellipsis"
                        >
                          { investment.offering.name }
                        </Col>

                        <Col
                          xs={12}
                          md={2}
                          className="text-center"
                        >
                          { currencyFormat(investment.amount) }
                        </Col>

                        <Col>
                          { investment.status === 'created' && (
                            <span className="badge bg-secondary-soft fs-5">Generating documents</span>
                          ) }

                          { investment.status === 'prepared' && (
                            <span className="badge bg-secondary-soft fs-5">Review pending</span>
                          ) }

                          { investment.status === 'reviewed' && (
                            <span className="badge bg-warning fs-5">Signature pending</span>
                          ) }

                          { investment.status === 'declined' && (
                            <span className="badge bg-danger-soft fs-5">Signature declined</span>
                          ) }

                          { investment.status === 'signed' && (
                            <span className="badge bg-info-soft fs-5">Payment pending</span>
                          ) }

                          { investment.status === 'paid' && (
                            <span className="badge bg-success-soft fs-5">Paid</span>
                          ) }
                        </Col>

                        <Col
                          xs={12}
                          md={2}
                          className="text-right"
                        >
                          { investment.updated_at }
                        </Col>

                        <Col
                          xs="auto"
                          className="text-right"
                        >
                          { (investment.status === 'created' || investment.status === 'prepared') && (
                            <Link
                              to={`/investments/${investment.id}/review`}
                              className="btn btn-primary btn-sm"
                            >
                              Review
                            </Link>
                          ) }

                          { (investment.status === 'reviewed') && (
                            <Link
                              to={`/investments/${investment.id}/sign`}
                              className="btn btn-primary btn-sm"
                            >
                              Sign
                            </Link>
                          ) }

                          { (investment.status === 'signed') && (
                            <Link
                              to={`/investments/${investment.id}/transfer`}
                              className="btn btn-primary btn-sm"
                            >
                              View
                            </Link>
                          ) }

                          { (investment.status === 'paid' || investment.status === 'declined') && (
                            <button
                              className="btn btn-primary btn-sm disabled"
                            >
                              View
                            </button>
                          ) }
                        </Col>
                      </Row>
                    )) }
                  </Col>
                </Row>
              </div>
            )
          }) }
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h4 className="card-header-title">
                Investor information
              </h4>
            </Col>

            <Col xs="auto">
              <Link
                to="/account/investor"
                className="btn btn-outline-primary btn-sm"
              >
                Edit details &raquo;
              </Link>
            </Col>
          </Row>
        </Card.Header>

        <Card.Body>
          { data.investors.length === 0 && (
            <p className="text-center small mb-0">
              No information available yet.
            </p>
          ) }
          
          { data.investors.map((investor: { [key: string]: any }, index: number) => {
            return (
              <div key={investor.id}>
                { data.investors.length > 1 && (
                  <p className="small font-weight-bold">
                    Investor {index+1}: { investor.name }
                  </p>
                ) }

                <Row className={index < data.investors.length-1 ? 'mb-5' : 'mb-2'}>
                  <Col
                    xs={12}
                    md={4}
                  >
                    <p className="small text-muted mb-1">Name</p>
                      
                    <p className="mb-0">{ investor.name }</p>
                  </Col>

                  { 
                    investor.type === 'legal'
                      ? (
                        <Col
                          xs={12}
                          md={4}
                        >
                          <p className="small text-muted mb-1">Company number</p>
                            
                          <p className="mb-0">{ investor.entity_number }</p>
                        </Col>
                      )
                      : (
                        <Col
                          xs={12}
                          md={4}
                        >
                          <p className="small text-muted mb-1">Birth date</p>
                            
                          <p className="mb-0">{ investor.birth_date }</p>
                        </Col>
                      )
                  }

                  <Col
                    xs={12}
                    md={4}
                  >
                    <p className="small text-muted mb-1">Country</p>
                      
                    <p className="mb-0">{ countryName(investor.country) }</p>
                  </Col>
                </Row>
              </div>
            )
          }) }
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h4 className="card-header-title">
                User information
              </h4>
            </Col>

            <Col xs="auto">
              <Link
                to="/account/user"
                className="btn btn-outline-primary btn-sm"
              >
                Edit details &raquo;
              </Link>
            </Col>
          </Row>
        </Card.Header>

        <Card.Body>
          <Row className="mb-4">
            <Col
              xs={12}
              md={4}
            >
              <p className="small text-muted mb-1">First name</p>
                
              <p className="mb-0">{ data.user.first_name }</p>
            </Col>

            <Col
              xs={12}
              md={4}
            >
              <p className="small text-muted mb-1">Last name</p>
                
              <p className="mb-0">{ data.user.last_name }</p>
            </Col>

            <Col
              xs={12}
              md={4}
            >
              <p className="small text-muted mb-1">Country</p>
                
              <p className="mb-0">{ countryName(data.user.country) }</p>
            </Col>
          </Row>

          <Row>
            <Col
              xs={12}
              md={4}
            >
              <p className="small text-muted mb-1">Phone number</p>
                
              <p className="mb-0">{ data.user.phone }</p>
            </Col>

            <Col
              xs={12}
              md={4}
            >
              <p className="small text-muted mb-1">Email address</p>
                
              <p className="mb-0">{ data.user.email }</p>
            </Col>

            <Col
              xs={12}
              md={4}
            >
              <p className="small text-muted mb-1">Password</p>
                
              <p className="mb-0">••••••••••</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}
