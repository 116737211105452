import { Card, Col, Row } from 'react-bootstrap'
import { Briefcase, User, X } from 'react-feather'
import { Link } from 'react-router-dom'

import AuthLayout from './layouts/AuthLayout'

export default function AccountInvestorNew() {
  return (
    <AuthLayout
      headerRight={
        <Link
          to="/account/investor"
          className="btn btn-sm btn-outline-primary d-flex flex-row align-items-center"
        >
          <X
            size="12px"
            className="me-2"
          />

          Back to Investor information
        </Link>
      }
    >
      <Col
        xs={12}
        sm={12}
        md={10}
        lg={8}
        xl={6}
      >
        <h1 className="text-center">
          How would you like to invest?
        </h1>

        <p className="text-center text-muted mb-5">
          Please choose the legal entity on behalf of which you will be depositing funds, performing investments and holding shares.
        </p>

        <Row>
          <Col xs={6}>
            <Link to="/account/investor/new/natural">
              <Card>
                <Card.Body className="p-4">
                  <p className="text-center">
                    <User size="32px" />
                  </p>

                  <p className="text-center mb-0">
                    As myself
                  </p>
                </Card.Body>
              </Card>
            </Link>
          </Col>

          <Col xs={6}>
            <Link to="/account/investor/new/legal">
              <Card>
                <Card.Body className="p-4">
                  <p className="text-center">
                    <Briefcase size="32px" />
                  </p>

                  <p className="text-center mb-0">
                    As a legal entity
                  </p>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        </Row>
      </Col>
    </AuthLayout>
  )
}
