import { useEffect, useState } from 'react'
import { Col, Modal, ModalProps, Row } from 'react-bootstrap'

export default function OfferingModalImages({name, images, ...props}: {name: string, images: Array<{preview: string, screen: string}>} & ModalProps) {
  const [current, setCurrent] = useState<number>(0)

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        setCurrent(current-1 >= 0 ? current-1 : images.length-1)
      }
      else if (event.key === 'ArrowRight') {
        setCurrent(current+1 < images.length ? current+1 : 0)
      }
    }

    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [current, images.length])

  return (
    <Modal
      centered
      size="lg"
      {...props}
    >
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">
          { name }: Images
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="text-center">
          <img
            src={images[current].screen}
            alt={name}
            style={{
              maxWidth: '100%'
            }}
          />
        </p>

        <hr />

        <Row>
          {
            images.map((image, index) => (
              <Col
                xs={4}
                md={3}
                lg={2}
                key={index}
              >
                <div
                  style={{
                    width: '100%',
                    paddingBottom: '100%',
                    backgroundImage: `url(${image.preview})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    cursor: 'pointer',
                    opacity: current === index ? 1.0 : 0.5,
                  }}
                  onClick={() => setCurrent(index)}
                />
              </Col>
            ))
          }
        </Row>
      </Modal.Body>
    </Modal>
  )
}
