import { Card, Col, ListGroup, Row } from 'react-bootstrap'
import { Briefcase, Key, Repeat, User } from 'react-feather'
import { Link, Outlet, useLocation } from 'react-router-dom'

export default function AccountLayout() {
  const location = useLocation()

  return (
    <>
      <h1 className="mt-5 mb-5">
        My account
      </h1>

      <Row className="flex-auto">
        <Col 
          xs={12}
          md={3}
        >
          <Card>
            <Card.Body>
              <ListGroup className="list-group-flush my-n3">
                <ListGroup.Item>
                  <Link
                    to="/account"
                    className={location.pathname === '/account' ? 'font-weight-bold text-info' : ''}
                  >
                    <Key
                      size="18px"
                      className="me-3"
                    />
                    
                    Account overview
                  </Link>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Link
                    to="/account/transactions"
                    className={location.pathname === '/account/transactions' ? 'font-weight-bold text-info' : ''}
                  >
                    <Repeat
                      size="18px"
                      className="me-3"
                    />
                    
                    Transactions
                  </Link>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Link
                    to="/account/investor"
                    className={location.pathname === '/account/investor' ? 'font-weight-bold text-info' : ''}
                  >
                    <Briefcase
                      size="18px"
                      className="me-3"
                    />
                    
                    Investor information
                  </Link>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Link
                    to="/account/user"
                    className={location.pathname === '/account/user' ? 'font-weight-bold text-info' : ''}
                  >
                    <User
                      size="18px"
                      className="me-3"
                    />
                    
                    User information
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>

        <Col
          xs={12}
          md={9}
          className="d-flex flex-column"
        >
          <Outlet />
        </Col>
      </Row>
    </>
  )
}
